const translationsResetPassword = {
    en: {
      title: "Reset Your Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm New Password",
      buttonReset: "Reset Password",
      successMessage: "Your password has been reset successfully.",
      errorEmptyPassword: "Please enter your new password!",
      errorPasswordMismatch: "Passwords do not match!",
      errorResetFailure: "Failed to reset password. Please try again."
    },
    de: {
      title: "Setzen Sie Ihr Passwort zurück",
      newPassword: "Neues Passwort",
      confirmNewPassword: "Neues Passwort bestätigen",
      buttonReset: "Passwort zurücksetzen",
      successMessage: "Ihr Passwort wurde erfolgreich zurückgesetzt.",
      errorEmptyPassword: "Bitte geben Sie Ihr neues Passwort ein!",
      errorPasswordMismatch: "Die Passwörter stimmen nicht überein!",
      errorResetFailure: "Passwort zurücksetzen fehlgeschlagen. Bitte versuchen Sie es erneut."
    }
  };
  
  export default translationsResetPassword;
  