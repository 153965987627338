import React from 'react'
import discountBgColor from "../../assets/images/Rectangle_147.png"
import { Link } from 'react-router-dom'
import ArrowRight from '../../assets/icons/ArrowRight';
function FirstOrderDiscount() {
  return (
    <div className='w-11/12 text-white text-center flex flex-col items-center  justify-center m-auto h-[390px] bg-center bg-cover bg-no-repeat bg-black/30 bg-blend-overlay ' style={{backgroundImage:`url(${discountBgColor})`}} >
        <p className='text-[17px]'>Timeless Beauty, Enduring Quality</p>
        <h5 className='text-[28px] md:text-[40px] mt-6'>Classy Pottery Creations</h5>
        <h6 className='text-[20px] md:text-[30px] mt-2'>Save 10% On Your First-Time Order</h6>

        <div className='mt-5'>
                <Link to='/products' className={`text-white m-auto  bg-base-accent-1 hover:text-base-accent-1 hover:bg-[rgba(0,0,0,0)]  tracking-[0.1rem] duration-500 button_hover   flex items-center  justify-center  gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[220px] text-center border border-1 border-base-accent-2`}>
                Shop Now
                <span>
                    <ArrowRight />
                </span>

            </Link>
        </div>
    </div>
  )
}

export default FirstOrderDiscount;