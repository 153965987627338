import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import getToken from '../../utils/getToken';
import { showInfo } from '../../utils/toastUtils';
import { Toast } from 'primereact/toast';


const FileUpload = () => {
    const toast = useRef(null); // Toast reference
    const { id } = useParams();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const handleUpload = async (event) => {
        event.preventDefault();
        if (selectedFiles.length === 0) {
            setMessage('Please select a file.');
            return;
        }

        const token = getToken();
        setIsSubmitting(true);

        try {
            const uploadPromises = selectedFiles.map(file => {
                const formData = new FormData();
                formData.append('file', file);
                return  axios.post(`http://85.215.136.36:8090/api/v1/product_images?productId=${id}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
            });

            await Promise.all(uploadPromises);
            showInfo(toast, "success", "Success", 'Files sent successfully!');
            setSelectedFiles([]); // Clear the file input after upload
        } catch (error) {
            showInfo(toast, "error", "Error", `${error.response ? error.response.data.message : error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Toast ref={toast} /> {/* Toast Component */}

            <form onSubmit={handleUpload} className="flex flex-col items-center">
            
            <label className="flex items-center justify-center w-16 h-16 rounded-full border-2 border-gray-400 cursor-pointer hover:bg-gray-200">
                <input type="file" onChange={handleFileChange} className="hidden" multiple />
                <span className="text-2xl text-gray-400">+</span>
            </label>
            <button
                type="submit"
                className={`my-4 px-4 py-2 text-white ${isSubmitting ? 'bg-gray-400' : 'bg-base-accent-1'} rounded hover:${isSubmitting ? '' : 'bg-green-600'}`}
                disabled={isSubmitting}
            >
                {isSubmitting ? 'Sending...' : 'Send'}
            </button>
            {selectedFiles.length > 0 && (
                <div className="flex flex-wrap  my-5 items-center">
                    {selectedFiles.map((file, index) => (
                        <div key={index} className="flex items-center mx-2">
                            <img src={URL.createObjectURL(file)} alt={`Uploaded ${file.name}`} className="w-24 h-24 object-cover border rounded" />
                            
                        </div>
                    ))}
                </div>
            )}
            {message && <p className="mb-4 text-red-500">{message}</p>}
        </form>
        </div>
    );
};

export default FileUpload;