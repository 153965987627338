import React from 'react';
import formatPrice from '../../utils/formatPrice';
import { Link } from 'react-router-dom';

function ShoppingCartCard({ product }) {
  return (
    <>
      {/* Display for larger screens */}
      <tr key={product.id} className="hidden md:table-row border-b">
        <td className="py-5">
          <div className="flex gap-3">
            <img
              className="w-[100px] h-[100px] rounded-[3px]"
              src={product.images.length && product.images[0].imageUrl}
              alt={product.translations.length && product.translations[0].name}
            />
            <div className="flex flex-col justify-center text-base-accent-1">
              <Link to={`/products/product-details/${product.id}`}>
                <p>{product.translations.length && product.translations[0].name}</p>
              </Link>
              <p>Rs.{formatPrice(product.price)}</p>
            </div>
          </div>
        </td>
        <td>{product.quantity}</td>
        <td>${(product.price * product.quantity).toFixed(2)}</td>
      </tr>

      {/* Display for smaller screens */}
      <div className="block w-11/12 m-auto md:hidden  py-4">
        <div className="flex flex-col gap-3">
          <img
            className="w-8/12 m-auto rounded-[3px]"
            src={product.images.length && product.images[0].imageUrl}
            alt={product.translations.length && product.translations[0].name}
          />
          <div className="flex gap-5 flex-col items-center text-base-accent-1">
            <Link to={`/products/product-details/${product.id}`}>
              <p className="font-medium">{product.translations.length && product.translations[0].name}</p>
            </Link>
            <p>Rs.{formatPrice(product.price)}</p>
          </div>
        </div>
        <div className=" flex flex-col  items-center text-base-accent-2">
          <p>Quantity: {product.quantity}</p>
          <p>Total: ${product.price * product.quantity}</p>
        </div>
      </div>
    </>
  );
}

export default ShoppingCartCard;
