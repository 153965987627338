// utils/convertToi18nFormat.js
const addTranslations = (products, i18n) => {
  const translations = products.reduce((acc, product) => {
    product.translations.forEach((translation) => {
      if (!acc[translation.languageCode]) {
        acc[translation.languageCode] = {};
      }
      // Store the product name for each language
      acc[translation.languageCode][`productName-${product.id}`] = translation.name;
    });
    return acc;
  }, {});

  // Add the translations to i18next
  Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'product', translations[lang], true, true);
  });
};

export default addTranslations;
