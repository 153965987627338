
import { useState } from "react";
import Button from "../../Button/Button"
import { Link } from "react-router-dom";
import ArrowRight from "../../../assets/icons/ArrowRight";
function SlideBox({title,description,button,bgImage,image}) {
  
  return (
    <div  style={{backgroundImage: `url(${
    bgImage
    })`}} className="bg-center bg-cover h-[500px] ">
      <div className="w-11/12 h-full flex gap-5  m-auto">
        <div className="w-full h-full md:w-5/12 flex gap-6 pl-5  flex-col justify-center">
          <h3 className="text-base-accent-1 text-[30px] pt-10 md:pt-2 md:text-[40px] leading-[2.8rem]">{title}</h3>
          <p className="text-gradient-base-accent-2 text-[0.9rem] md:text-[1.1rem] text-justify leading-7 ">{description}</p>
         <span className=" md:mt-9">
         <Link to='/products' className={`text-base-accent-1  bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover   flex items-center  justify-center  gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[220px] text-center border border-1 border-base-accent-2`}>
                shopNow
                <span>
                   <ArrowRight />
                </span>

            </Link>

</span> 
        </div>
        <div className="hidden w-1/2 md:flex items-center relative h-[500px]">
          <img src={image}alt="imageSlider" className="md:w-3/4 hidden md:block absolute bottom-0  w-auto  left-0"/>


        </div>

      </div>


    </div>
  )
}

export default SlideBox