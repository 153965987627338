const translationsForgotPassword = {
    en: {
      resetPasswordTitle: "Reset your password",
      resetPasswordMessage: "We will send you an email to reset your password",
      emailPlaceholder: "Email *",
      submitButton: "Submit",
      cancelLink: "Cancel",
      successMessage: "We will send you an email to reset your password",
      errorEmptyEmail: "Please enter your email!",
      errorSendFailure: "Failed to send! Please try again."
    },
    de: {
      resetPasswordTitle: "Setze dein Passwort zurück",
      resetPasswordMessage: "Wir senden Ihnen eine E-Mail, um Ihr Passwort zurückzusetzen",
      emailPlaceholder: "E-Mail *",
      submitButton: "Einreichen",
      cancelLink: "Abbrechen",
      successMessage: "Wir senden Ihnen eine E-Mail, um Ihr Passwort zurückzusetzen",
      errorEmptyEmail: "Bitte geben Sie Ihre E-Mail-Adresse ein!",
      errorSendFailure: "Senden fehlgeschlagen! Bitte versuche es erneut."
    }
  };
  
  export default translationsForgotPassword;
  