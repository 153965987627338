import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Toast } from 'primereact/toast';
import BannerAccount from "../components/banner/BannerAccount";
import { useTranslation } from "react-i18next";
import { showInfo } from "../utils/toastUtils";
import { useDispatch, useSelector } from 'react-redux';
import { login, setUser } from "../store/slices/authSlice";
import axios from "axios";
import CanvasCaptcha from "../components/captch/Captch";

function SignIn() {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [captchaMessageShown, setCaptchaMessageShown] = useState(false); // برای پیام‌های CAPTCHA
  const [formMessageShown, setFormMessageShown] = useState(false); // برای پیام‌های فرم
  const isAuth = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { signIn, userNameInput, password, forgotPass, signUp } = i18n.getDataByLanguage(i18n.language);

  const toast = useRef(null);
  const [loginForm, setLoginForm] = useState({
    usernameOrEmail: "",
    password: "",
  });

  useEffect(() => {
    if (isAuth) navigate('/');
  }, [isAuth, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const sendLoginForm = async (event) => {
    event.preventDefault();
    setFormMessageShown(true); // پیام‌های فرم نشان داده شود
    if (!loginForm.usernameOrEmail || !loginForm.password) {
      showInfo(toast, 'error', 'Error', 'Please fill in all fields!');
      return;
    }
    if (!isVerified) {
      showInfo(toast, 'error', 'Error', 'Please enter the captcha code.');
      return;
    }
    try {
      const res = await axios.post("http://85.215.136.36:8090/api/v1/auth/signin", loginForm);
      const { accessToken } = res.data;

      if (accessToken) {
        dispatch(login({ accessToken }));
        const userResponse = await axios.get(
          `http://85.215.136.36:8090/api/v1/auth/user-info?token=${accessToken}`
        );
        const user = userResponse.data;
        dispatch(setUser(user));
        showInfo(toast, 'success', 'Success', 'You have successfully logged in!');
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      showInfo(toast, 'error', 'Error', 'Failed to log in! Please try again.');
    }
  };

  return (
    <>
      <div>
        <BannerAccount title={signIn} />
        <div className="text-base-accent-1 w-11/12 md:3/4 lg:w-1/2 m-auto flex flex-col gap-5 items-center text-center my-20 justify-center">
          <h5 className="text-[30px]">{signIn}</h5>
          <form onSubmit={(event)=>event.preventDefault()} className="flex flex-col w-full m-auto md:w-11/12 gap-5 mt-6 text-[14px]">
            <Toast className="z-50" ref={toast} />
            <input
              type="text"
              value={loginForm.usernameOrEmail}
              placeholder={userNameInput}
              name="usernameOrEmail"
              onChange={handleChange}
              className="py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border"
            />
            <input
              type="password"
              value={loginForm.password}
              placeholder={password}
              name="password"
              onChange={handleChange}
              className="py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border"
            />
            <CanvasCaptcha
              isVerified={isVerified}
              setIsVerified={setIsVerified}
              setCaptchaMessageShown={setCaptchaMessageShown} // پیام CAPTCHA
            />
            <div className="mx-auto w-full">
              <button onClick={sendLoginForm} className="text-base-accent-1 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[100%] md:w-[130px] md:mx-auto text-center border border-1 border-base-accent-2">
                {signIn}
              </button>
            </div>
          </form>
          <hr className="h-px w-full md:mt-6 bg-gray-300 border-0" />
          <div className="flex w-full mt-2 justify-between text-[14px] md:text-[15px]">
            <Link to="/account/forgot-password" className="underline text-base-accent-1">
              {forgotPass}
            </Link>
            <Link to="/account/register" className="underline text-base-accent-1">
              {signUp}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
