import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Pagination from '../../components/shopping/pagination/Pagination';
import ShopFilters from '../../components/shopping/shopFilters/ShopFlters';
import Loader from '../../components/Loader/Loader';
import GridLayout from '../../components/shopping/GridLayout/GridLayout';
import BannerAccount from '../../components/banner/BannerAccount';

const ShopPage = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    minPrice: '',
    maxPrice: '',
    sortOrder: 'desc',
    search: '',
  });
  const [pageSize, setPageSize] = useState(4); // Default page size
  const [currentPage, setCurrentPage] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  // Update URL parameters when filters or page changes
  useEffect(() => {
    const params = {
      pageNo: currentPage,
      pageSize,
      sortBy: 'id',
      sortDir: filters.sortOrder,
      ...(filters.minPrice && { minPrice: filters.minPrice }),
      ...(filters.maxPrice && { maxPrice: filters.maxPrice }),
      ...(filters.search && { search: filters.search }),
    };
    setSearchParams(params);
  }, [filters, currentPage, pageSize, setSearchParams]);

  // Fetch products from API
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const params = {
        pageNo: currentPage,
        pageSize,
        sortBy: 'id',
        sortDir: filters.sortOrder,
        ...(filters.minPrice && { minPrice: filters.minPrice }),
        ...(filters.maxPrice && { maxPrice: filters.maxPrice }),
        ...(filters.search && { search: filters.search }),
      };

      const queryString = new URLSearchParams(params).toString();
      const response = await axios.get(
        `http://85.215.136.36:8090/api/v1/products/category/${id}?${queryString}`
      );

      const data = response.data;
      setProducts(data.content);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(0); // Reset to first page when filters change
  };

  // Fetch data when changing filters or page
  useEffect(() => {
    fetchProducts();
  }, [currentPage, filters, id]); // Re-fetch products when page, filters, or category id change

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="mb-16">
          <BannerAccount title="Products" />
          <div className="w-11/12 flex flex-col lg:flex-row gap-10 m-auto my-16">
            {/* Filters */}
            <div className="w-2/3 lg:w-1/4">
              <ShopFilters filters={filters} handleApplyFilters={handleApplyFilters} />
            </div>

            {/* Products and Pagination */}
            {products.length ? (
              <div className="w-full lg:w-3/4">
                <GridLayout products={products} />
              </div>
            ) : (
              <div className="flex items-center w-full justify-center text-[20px] text-gray-400">
                <p>There are no products</p>
              </div>
            )}
          </div>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default ShopPage;
