import axios from 'axios';
import  { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import BannerShop from '../../components/banner/bannerShop';
import {  useSelector } from 'react-redux';
import ProductImages from '../../components/shopping/ProductDetails/ProductImages';
import ProductDetailsSection from '../../components/shopping/ProductDetails/ProductDetailsSection';



function ProductDetails() {
  const {pathname}=useLocation()
  const {loading}= useSelector((state) => state.shop);
  const { id } = useParams(); // Extract product ID from the URL parameters
  const [product, setProduct] = useState([]); // State to store the product details
  const [error, setError] = useState(false); // State to handle errors
  const [isLoading, setLoading] = useState(false); // State to manage loading status
  const [selectedImage, setSelectedImage] = useState(''); // State for the selected main image
  const [shareLink, setShareLink] = useState(''); // State to store the shareable link
  const [isCopied, setIsCopied] = useState(false); // State to track if the link is copied
  const [productCategory,setProductCategory]=useState(null)
  const [categoryId,setCategoryId]=useState(null);
  //auth user
  //const {isAuthenticated}=useSelector(state=>state.auth)
  //end auth user
  //cart id
  //const { cartId, products } = useSelector((state) => state.cart);
  //end cart id
  useEffect(() => {
    // Function to fetch product details
    const getProduct = async () => {
      setLoading(false)
      try {
        const response = await axios.get(`http://85.215.136.36:8090/api/v1/products/${id}`); // API call to fetch product by ID
        console.log(response);
        setCategoryId(response.data.productCategoryId)
        setLoading(true)
        setProduct(response.data); // Set product details in state
        setSelectedImage(response.data.images[0]?.imageUrl); // Set the first image as the default selected image
        setShareLink(`${window.location.origin}/products/${id}`); // Generate the shareable link
      } catch (error) {
        setError(error.message); // Handle any errors during the fetch
        console.log(error);
      }
    };
    if (id) getProduct(); // Fetch product details only if an ID is available
    console.log({categoryId})
    const getProductCategory=async () => {
      try {
        const response = await axios.get(`http://85.215.136.36:8090/api/v1/product_categories/${categoryId}`); 
        console.log({response});
        setProductCategory(response.data)
        setLoading(true); // Set loading to true once data is fetched
      } catch (error) {
        setError(error.message);
        console.log(error);
      }
    };

    if (categoryId)
      getProductCategory()
  }, [id,pathname]);

  // Function to copy the shareable link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setIsCopied(true); // Set copied status to true
      setTimeout(() => setIsCopied(false), 2000); // Reset the copied status after 2 seconds
    });
  };
if (!isLoading)
{
  return <Loader />
}
  return (
    <>
      {!isLoading && !product && (loading=='error' || loading=='loading') ? (
        // Show loader while the product data is being fetched
        <Loader />
      ) : (
        <>
          {/* Render the banner with the product name */}
         <BannerShop title={product.translations && product.translations?.length && product.translations[0].name} />
          <div className="flex flex-col relative md:flex-row items-start md:gap-5 lg:gap-10 w-11/12 my-20 m-auto">
            
            {/* Image Section */}
           <ProductImages selectedImage={selectedImage} setSelectedImage={setSelectedImage} product={product} />
            {/* Product Details Section */}
            <ProductDetailsSection isCopied={isCopied} productCategory={productCategory} handleCopyLink={handleCopyLink} product={product}/>
          </div>
        </>
      )}
              

    </>
  );
}

export default ProductDetails;
