import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import { Provider } from 'react-redux';
import store from './store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PrimeReactProvider>
        <BrowserRouter>
              <App />
        </BrowserRouter>
     </PrimeReactProvider>
    </Provider>
 
);