import axios from 'axios';
import getToken from '../utils/getToken';

// ایجاد یک نمونه Axios
export  const api = axios.create({
    baseURL: 'http://85.215.136.36:8090/api/', // آدرس API خود را تنظیم کنید
});

// اضافه کردن interceptor برای افزودن توکن به هدر
api.interceptors.request.use(config => {
   const token=getToken()    
    // اگر درخواست نیاز به احراز هویت دارد
    if (config.requiresAuth && token) {
        config.headers['Authorization'] = `Bearer ${token}` // افزودن هدر Authorization
    }
    
    return config;
}, error => {
    return Promise.reject(error);
});

// توابع برای API
export const fetchPublicData = async (endpoint) => {
    return await api.get(endpoint); // عمومی
};

export const fetchProtectedData = async (endpoint) => {
    return await api.get(endpoint, { requiresAuth: true }); // محافظت‌شده
};

export const createData = async (data,endpoint) => {
    return await api.post(endpoint, data, { requiresAuth: true }); // محافظت‌شده
};

export const updateData = async (id, data,endpoint) => {
    return await api.put(`/${endpoint}/${id}`, data, { requiresAuth: true }); // محافظت‌شده
};

export const deleteData = async (id,endpoint) => {
    return await api.delete(`/${endpoint}/${id}`, { requiresAuth: true }``); // محافظت‌شده
};

