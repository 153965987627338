import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/masoorehLogo.jpeg';
import ListItems from '../../assets/icons/ListItems';
import Home from '../../assets/icons/Home';
import Pencil from '../../assets/icons/Pencil';
import Location from '../../assets/icons/Location';
import { useSelector } from 'react-redux';

export default function UserVerticalMenu() {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  console.log(user);

  // active class for links
  const getActiveClass = (path) =>
    location.pathname === path
      ? 'bg-[rgba(16,84,77,0.5)] text-white font-bold'
      : 'hover:bg-[rgba(16,84,77,0.2)]';

  return (
    <div className="relative">
      {/* Overlay background when menu is open, but not on the menu itself */}
      <div className={`sticky h-full bg-white md:border-r md:w-[300px] z-20`}>
        {/* Logo */}
        <div className="flex justify-center p-4">
          <img src={logo} className="w-[200px]" alt="masooreh logo" />
        </div>

        {/* Sidebar links */}
        <div className="divide-y text-base-accent-1 pt-10 flex flex-col">
          <Link
            to="/"
            className={`flex text-[11px] md:text-[16px] px-1 md:px-5 py-6 gap-2 items-center duration-300 ${getActiveClass('/')}`}
          >
            <span>
              <Home />
            </span>
            <p className="hidden md:block">Home Page</p>
          </Link>

          <Link
            to="/user/dashboard"
            className={`flex text-[11px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/user/dashboard')}`}
          >
            <span>
              <Pencil />
            </span>
            <p className="hidden md:block">Account</p>
          </Link>

          <Link
            to="/user/orders"
            className={`flex text-[11px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/user/orders')}`}
          >
            <span>
              <ListItems />
            </span>
            <p className="hidden md:block">Orders</p>
          </Link>

          <Link
            to="/user/addresses"
            className={`flex text-[11px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/user/addresses')}`}
          >
            <span>
              <Location />
            </span>
            <p className="hidden md:block">Addresses</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
