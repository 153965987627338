


const translations = {
  en: {
    titleAbove:' Handmade Products ',
    descriptionAbove:"Duis aute irure dolor in reprehenderit in voluptate eu fugiat nulla pariatur.",
   
  },
  de: {
    titleAbove:' Handgefertigte Produkte ',
    descriptionAbove:"Duis aute irure dolor in reprehenderit in voluptate eu fgiat nulla pariatur.",
    
  }
};

export default translations;