// utils/getToken.js
export default function getToken() {
    const storedToken = localStorage.getItem("authToken");
  
    if (!storedToken) {
      return null;  
    }
  
    try {
      const { token, expiry } = JSON.parse(storedToken);
  
      const now = new Date().getTime();
  
      if (now > expiry) {
        localStorage.removeItem("authToken"); 
        window.location.reload();
        return null;
      }
  
      return token; 
    } catch (error) {
      localStorage.removeItem("authToken"); 
      window.location.reload(); 
      return null;
    }
  }
  