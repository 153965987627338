import formatPrice from "../../../utils/formatPrice";
import Share from '../../../assets/icons/Share';

const ProductDetails = ({ product , isCopied, productCategory,handleCopyLink}) => {
  return(
    <>
     <h2 className="text-xl md:text-2xl mt-5 font-semibold">
        {product.translations?.length && product.translations[0].name}
      </h2>

      {/* Product Description */}
      <p className="text-base-accent-2 text-justify">
        {product.translations?.length && product.translations[0].description}
      </p>

      {/* Product Price */}
      <p className="text-[20px] md:text-[30px] font-Montserrat">
        Rs. {formatPrice(product?.price)}
      </p>
      <p className="text-base-accent-1">
        Product Category :{" "}
        {productCategory?.translations?.length &&
          productCategory.translations[0].name}
      </p>
     {product.active && <p className="bg-base-accent-1 text-white p-1 px-5 w-fit rounded-[20px] ">
        Hurry! Only {product.stockQuantity} left in stock
      </p>
      }
      {!product.active && <p className="bg-base-accent-1 text-white p-1 px-5 w-fit rounded-[20px] ">
        out of stock
      </p>
      }

      {/* Share Button */}
      <div>
        <button
          onClick={handleCopyLink}
          className="text-base-accent-1 rounded hover:text-base-background-3 transition"
        >
          {isCopied ? (
            "Copied!"
          ) : (
            <div className="flex items-center gap-2">
              <Share /> <p>share</p>
            </div>
          )}
        </button>
        {isCopied && (
          <span className="ml-2 text-green-600 text-sm">
            Link copied to clipboard!
          </span>
        )}
      </div>

    </>

    
  )
}
  
  
  export default ProductDetails;
  