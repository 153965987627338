import banner from "../../assets/images/Testimonial.jpg"
function BannerShop({title}) {
  return (
    <div style={{backgroundImage: `url(${banner})`}} className="h-[200px] md:h-[300px] bg-gradient-base-accent-3/50 bg-blend-overlay flex items-center justify-center flex-col bg-cover md:bg-top">
            <h4 className="text-base-accent-1 text-[25px] md:text-[50px]">{title}</h4>
            <div className="flex gap-10 text-[18px] md:text-[20px]">
               
            </div>
    </div>
  )
}

export default BannerShop;