import axios from 'axios';
import { Toast } from 'primereact/toast';
import React, { useEffect, useState } from 'react'
import { showInfo } from '../../utils/toastUtils';
import getToken from '../../utils/getToken';
import { Link } from 'react-router-dom';

function Users() {
  const [users, setUsers] = useState([]);
  const token=getToken()
  // Fetch Users from API
  useEffect(() => {
    const fetchProducts = async () => {
        try {
            const response = await axios.get('http://85.215.136.36:8090/api/v1/auth/users',{
              headers:{
                Authorization:`Bearer ${token}`
              }
            });
            setUsers(response.data);
            console.log(response.data);
        } catch (error) {

        }
    };

    fetchProducts();
}, []);

// تابعی برای تولید رنگ تصادفی
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
  return (
  
      <div className="p-4 mb-4 w-11/12  m-auto">
        <h5 list className="text-xl text-base-accent-1 font-bold mb-4"> User list </h5>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {users.map(user => (
            <Link to={`/admin/users/user-info/${user.id}`} key={user.id} className="flex flex-col items-center p-4 border rounded-lg shadow-md hover:shadow-lg transition">
              <div
                className="w-16 h-16 flex items-center justify-center rounded-full text-white font-bold"
                style={{ backgroundColor: getRandomColor() }}
              >
                {user.name.charAt(0)}
              </div>
              <h2 className="mt-2 text-lg">{`${user.name} ${user.familyName}`}</h2>
              <p className="text-gray-600">{user.email}</p>
              <p className={`mt-1 ${user.active ? 'text-green-500' : 'text-red-500'}`}>
                {user.active ? 'Active' : 'Inactive'}
              </p>
            </Link>
          ))}
        </div>
      </div>
  
  
  )
}

export default Users