import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { showInfo } from "../utils/toastUtils";  
import { useTranslation } from 'react-i18next';
import axios from 'axios';


function SignUp() {
    const toast = useRef(null); // Toast reference
    const { i18n } = useTranslation();

    const { title_signUp, alreadyHaveAccount, signIn,
         placeholders: {
        firstName,
        familyName,
        email,
        password,
        id,
        street,
        city,
        state,
        postalCode,
        country
    },buttonCreate,successMessageRegister,errorMessageRegister } = i18n.getDataByLanguage(i18n.language);
  
    const [registerForm, setRegisterForm] = useState({
        name: "",
        familyName: "",
        email: "",
        password: "",
        addresses: [
            {
                id: '',
                street: "",
                city: "",
                state: "",
                postalCode: "",
                country: ""
            }
        ]
    });

   

    // Update state based on input changes
    const handleChange = (event) => {
        const { name, value } = event.target;

        // Check if the field is in addresses
        if (name.includes("addresses")) {
            const [addressIndex, field] = name.split(".");
            const index = parseInt(addressIndex.replace('addresses[', '').replace(']', ''));
            setRegisterForm(prevState => {
                const newState = { ...prevState };
                newState.addresses[index][field] = value;
                return newState;
            });
        } else {
            setRegisterForm(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    // Submit form
    const sendRegisterForm = async (event) => {
        event.preventDefault();
        try {
            const res = await axios.post("http://85.215.136.36:8090/api/v1/auth/register", registerForm);
            showInfo(toast, "success", "Success", successMessageRegister);
        } catch (error) {
            const error_ = error.response?.data?.message || errorMessageRegister;
            showInfo(toast, "error", "Error", error_);
        }
    };

    return (
        <div className='text-base-accent-1 w-11/12 md:3/4 lg:w-1/2 m-auto flex-flex-col gap-5  items-center text-center my-20 justify-center'>
            <Toast ref={toast} /> {/* Toast Component */}
            <h5 className='text-[30px]'>{title_signUp}</h5>
            <p>{alreadyHaveAccount} <Link to="/account/login" className='underline'>{signIn}</Link></p>
            <form className='flex flex-col w-full m-auto md:w-11/12 gap-5 mt-6 text-[14px]' onSubmit={sendRegisterForm}>
                <input
                    type="text"
                    value={registerForm.name}
                    placeholder={firstName}
                    name="name"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border'
                />
                <input
                    type="text"
                    value={registerForm.familyName}
                    placeholder={familyName}
                    name="familyName"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border'
                />
                <input
                    type="email"
                    value={registerForm.email}
                    placeholder={email}
                    name="email"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border'
                />
                <input
                    type="password"
                    value={registerForm.password}
                    placeholder={password}
                    name="password"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1  border-base-second_border'
                />
                <input
                    type="text"
                    value={registerForm.addresses[0].id}
                    placeholder={id}
                    name="addresses[0].id"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border'
                />
                <input
                    type="text"
                    value={registerForm.addresses[0].street}
                    placeholder={street}
                    name="addresses[0].street"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border'
                />
                <input
                    type="text"
                    value={registerForm.addresses[0].city}
                    placeholder={city}
                    name="addresses[0].city"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border'
                />
                <input
                    type="text"
                    value={registerForm.addresses[0].state}
                    placeholder={state}
                    name="addresses[0].state"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border'
                />
                <input
                    type="text"
                    value={registerForm.addresses[0].postalCode}
                    placeholder={postalCode}
                    name="addresses[0].postalCode"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border'
                />
                <input
                    type="text"
                    value={registerForm.addresses[0].country}
                    placeholder={country}
                    name="addresses[0].country"
                    onChange={handleChange}
                    className='py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border'
                />
                <div className='mx-auto w-full '>
                    <button
                        type="submit"
                        className='text-base-accent-1 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[100%] md:w-[130px] md:mx-auto text-center border border-1 border-base-accent-2'>
                        {buttonCreate}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default SignUp;
