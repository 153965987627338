import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_IS_OPEN } from "../../store/slices/CartOpenSlice";
import Xlg from "../../assets/icons/Xlg";

import Features from "./Features/Features";
import CartProducts from "./CartProducts";

function Cart({ 
  
  handleIncrease,
  handleDecrease,
 
  setIsClosing,
 }) {
  const products = useSelector((state) => state.cart);
  const isOpen = useSelector((state) => state.cartOpen.isOpen);
  const dispatch = useDispatch();
  // وضعیت انیمیشن برای باز شدن و بسته شدن
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true); // شروع انیمیشن باز شدن
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsAnimating(false); // شروع انیمیشن بسته شدن
    setTimeout(() => {
      setIsClosing(false);
      dispatch(SET_IS_OPEN(false)); // بستن واقعی سبد
    }, 100); // مدت زمان انیمیشن بسته شدن
  };

  return (
    <>
      {/* Overlay برای بک‌گراند مشکی */}
      <div
        className={`fixed inset-0  bg-opacity-50  transition-opacity duration-300 ${
          isOpen && isAnimating ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={handleClose}
      ></div>

      {/* سبد خرید */}
      <div
        className={`fixed overflow-y-scroll top-0 right-0 h-full w-full bg-white transition-transform duration-300 ease-in-out transform ${
          isOpen && isAnimating ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex relative shadow-md text-base-accent-1 justify-between py-8 px-5">
          <h4>Shopping Cart</h4>
          <span
            className="font-bold hover:cursor-pointer shadow-b-2"
            onClick={handleClose}
          >
            <Xlg />
          </span>
        </div>
        <div
          className=" overflow-y-auto"
          style={{ maxHeight: "calc(100vh - 300px)" }} 
        >
          {products.products.length === 0 ? (
            <p className="mt-10 px-5">Your cart is empty.</p>
          ) : (
            <CartProducts   products={products} // لیست محصولات
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            />)}
        </div>
        <Features />
      </div>
    </>
  );
}

export default Cart;
