import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getToken from '../../utils/getToken';
import axios from 'axios';

const UserInfo = () => {
  const [user, setUser] = useState(null);
  const { id } = useParams();
  const token = getToken();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`http://85.215.136.36:8090/api/v1/auth/user/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [id, token]);

  if (!user){
    return <div>
        <p>user is not found !</p>
    </div>
  }

  return (
    <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg p-6 my-5">
      <h5 className="text-xl  mb-4 text-center">{`${user.name} ${user.familyName}`}</h5>
      <div className="flex items-center justify-center mb-4">
        <div
          className="w-20 h-20 flex items-center justify-center rounded-full text-white font-bold"
          style={{ backgroundColor: getRandomColor() }} 
        >
          {user.name.charAt(0)}
        </div>
      </div>
      <p className="text-gray-700 mb-2">Email: <span className="text-base-accent-1">{user.email}</span></p>
      <p className={`text-lg mb-4 ${user.active ? 'text-green-500' : 'text-red-500'}`}>
        Status: {user.active ? 'Active' : 'Inactive'}
      </p>
      
      {/*addresses*/}
      <h2 className="text-xl font-semibold mb-2">Addresses:</h2>
      {user.addresses && user.addresses.length > 0 ? (
        user.addresses.map((address, index) => (
          <div key={index} className="border p-4 mb-2 rounded-lg shadow-sm">
          {address.street&&  <p className="text-gray-700">street: <span className="text-base-accent-1">{address.street}</span></p>}
          {address.city&&  <p className="text-gray-700">city: <span className="text-base-accent-1">{address.city}</span></p>}
          {address.country&&  <p className="text-gray-700">country: <span className="text-base-accent-1">{address.country}</span></p>}
          {address.postalCode &&  <p className="text-gray-700">postalCode :<span className="text-base-accent-1">{address.postalCode}</span></p>}
          </div>
        ))
      ) : (
        <p className="text-gray-600">No address found..</p>
      )}
    </div>
  );
};

// تابعی برای تولید رنگ تصادفی
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export default UserInfo;