import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import getToken from '../../utils/getToken';
import EditCategoryModal from './EditCategoryModal'
import { showInfo } from '../../utils/toastUtils';
import { Toast } from 'primereact/toast';
import Loader from '../Loader/Loader';

function AllCategories({categories,setCategories}) {
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const toast = useRef(null); // Toast reference
    console.log(categories)


    useEffect(() => {
        // Fetch categories from API 
        const fetchCategories = async () => {
            try {
                const response = await axios.get('http://85.215.136.36:8090/api/v1/product_categories');
                setCategories(response.data);
                
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
 
        fetchCategories();
    
      }, []);
    const handleEditClick = (category) => {
        setSelectedCategory(category);
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
        setSelectedCategory(null);
    };
    const handleDeleteClick = async (id) => {
      const token=getToken()
      try {
        const response = await axios.delete(`http://85.215.136.36:8090/api/v1/product_categories/${id}`,
          {
            headers:{
              Authorization:`Bearer ${token}`,
            }
          })
          showInfo(toast, "success", "Success", 'Category removed.');

          setCategories(categories.filter(category => category.id !== id));
        
        }
          catch(err)
          {
            console.log(err)
          }          
        }
        if (categories.length==0)
          return <Loader />
  return (
            <><ul className="space-y-4">
                <Toast ref={toast} /> {/* Toast Component */}
            {categories?.map(category => (
                <li key={category.id} className="flex justify-between items-center p-2 border border-gray-300 rounded">
                  <img className='w-[40px] h-[40px] rounded-[50%]' src={category.categoryImage!==null && category.categoryImage.imageUrl} />
                    <div>
                    <strong>{category.translations && category.translations.length>0 &&  category.translations[0].name}</strong>

                    </div>
                    <div>
                        <button onClick={() => handleEditClick(category)} className="mr-2 text-blue-600">Edit</button>
                        <button onClick={() => handleDeleteClick(category.id)} className="text-red-600">Delete</button>
                    </div>
                </li>
            ))}
        </ul>
        {showModal && (
                        <EditCategoryModal
                            category={selectedCategory} 
                            onClose={handleModalClose} 
                            setCategories={setCategories} // Pass setCategories to update the list
                        />
        )}
</>
  )
}

export default AllCategories