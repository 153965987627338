const getTotalCost = (products) => {
    console.log({ products });
    const total = products.reduce(
      (acc, cur) =>
        acc + (cur.quantity * cur.price),
      0
    );
    console.log(total);
    return total;
  };
export default getTotalCost;