import React from 'react'
import SkillBox from './SkillBox'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 

function Skills() {
  return (
    <div className='w-11/12 m-auto my-24'>
      <Swiper
        slidesPerView={3}
        spaceBetween={0}
        pagination={{ clickable: true }}
        breakpoints={{
            0:{
                slidesPerView:1
            },
          768: {
            slidesPerView:2,
          },
          998:{
            slidesPerView:3,
          }
        }}
      >
       {[1,2,3,4,5,6,7,8].map(item=><SwiperSlide><SkillBox /></SwiperSlide>)}
      </Swiper>
    </div>
  )
}

export default Skills