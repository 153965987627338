import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import masoorehLogo from "../../assets/images/masoorehLogo.jpeg";
import Bag from "../../assets/icons/Bag";
import LanguageSelection from "./LanguageSelection";
import {SET_IS_OPEN} from '../../store/slices/CartOpenSlice';
import ProfileButton from "../Button/ProfileButton";
import { useDispatch, useSelector } from "react-redux";
import ArrowDown from "../../assets/icons/ArrowDown";
import { fetchCategories } from '../../store/slices/categoriesSlice';

function DesktopHeader() {
  const categories = useSelector((state) => state.categories.items);
  const status = useSelector((state) => state.categories.status);
  const dispatch = useDispatch();
  const { id } = useParams();
  const products=useSelector(state=>state.shop.products)
  console.log('ppp',{products})
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (status == 'idle') dispatch(fetchCategories());
  }, [id]);
  

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  let submenuTimer = null;
  const handleMouseEnter = () => {
    clearTimeout(submenuTimer);
    setSubmenuOpen(true);
  };

  const handleMouseLeave = () => {
    submenuTimer = setTimeout(() => {
      setSubmenuOpen(false);
    }, 200);
  };

  const splitCategories = (categories || []).reduce(
    (result, category, index) => {
      const columnIndex = Math.floor(index / 2);
      if (!result[columnIndex]) result[columnIndex] = [];
      result[columnIndex].push(category);
      return result;
    },
    []
  );

  const toggleSubmenuMobile = () => {
    setSubmenuOpen((prev) => !prev);
  };

  //open cart
  const clickHandler=()=>{
    dispatch(SET_IS_OPEN(true))
  }
 
  return (
    <header className="w-full bg-white text-base-accent-1">
      {/* Desktop Header */}
      <div className="hidden md:flex justify-between items-center w-11/12 mx-auto py-6">
        <div className="w-1/4 flex items-center">
          <img src={masoorehLogo} alt="masooreh" className="h-10" />
        </div>
        <div className="w-2/4 flex justify-center">
          <nav className="relative flex space-x-8">
            {/* Home */}
            <Link
              to="/"
              className="text-base font-medium  hover:text-base-background-3 duration-200 flex items-center"
            >
              Home
            </Link>

            {/* Shop */}
            <div
              className="relative group"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <button
                className="flex items-center text-base font-medium  duration-200  hover:text-base-background-3"
              >
                <Link to="/categories">Shop</Link>
                <span
                  className={`ml-2 transition-transform  hover:text-base-background-3 duration-300 ${
                    submenuOpen ? "rotate-180" : "rotate-0"
                  }`}
                >
                   <ArrowDown />
                </span>
              </button>

              {/* Submenu */}
              {submenuOpen && (
                <div className="absolute z-50 mt-2 bg-white shadow-md p-4 left-0 w-64 border border-gray-200">
                  <div className="grid grid-cols-1 gap-2">
                    {splitCategories.map((column, colIndex) => (
                      <ul key={colIndex} className="space-y-2">
                        {column.map((category) => (
                          <li key={category.id} className="relative group">
                            <Link
                              to={`/categories/${category.id}`}
                              className="block px-4 py-2 duration-300 hover:translate-x-2  text-sm hover:text-base-background-3"
                            >
                              {category.translations.length &&
                                category.translations[0].name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* About Us */}
            <Link
              to="/about-us"
              className="text-base font-medium  duration-200   hover:text-base-background-3 flex items-center"
            >
              About Us
            </Link>
            <Link
              to="/"
              className="text-base font-medium   duration-200 hover:text-base-background-3 flex items-center"
            >
              Contact Us
            </Link>
          </nav>
        </div>
        <div className="w-1/4 flex items-center justify-end gap-6">
          <span className="cursor-pointer" onClick={clickHandler}>
            <Bag />
          </span>
          <ProfileButton />
          <LanguageSelection />
        </div>
      </div>

      {/* Mobile Header */}
      <div className="flex md:hidden justify-between items-center w-full px-4 py-4">
        <button onClick={toggleMenu} className="text-xl">
          ☰
        </button>
        <div>
          <img src={masoorehLogo} alt="masooreh" className="h-10 mx-auto" />
        </div>
        <span >
          <Bag />
        </span>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <nav className="bg-white shadow-md absolute w-full left-0 top-[60px] z-20">
          <ul className="flex flex-col space-y-2 py-4 px-6">
            <li>
              <Link
                to="/"
                className="block px-4 py-2 text-sm  hover:text-base-background-3"
                onClick={() => setMenuOpen(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <button
                onClick={toggleSubmenuMobile}
                className="flex justify-between items-center w-full px-4 py-2 text-sm  hover:text-base-background-3"
              >
                <span>Shop</span>
                <span
                  className={`transition-transform duration-300 ${
                    submenuOpen ? "rotate-180" : "rotate-0"
                  }`}
                >
                   <ArrowDown />
                </span>
              </button>
              {/* Submenu */}
              {submenuOpen && (
                <ul className="pl-4 mt-2 border-l border-gray-300">
                  {categories?.map((category) => (
                    <li key={category.id}>
                      <Link
                        to={`/categories/${category.id}`}
                        className="block z-50 px-4 py-2 text-sm  hover:text-base-background-3"
                        onClick={() => setMenuOpen(false)}
                      >
                        {category.translations.length &&
                          category.translations[0].name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li>
              <Link
                to="/about-us"
                className="block px-4 py-2 text-sm  hover:text-base-background-3"
                onClick={() => setMenuOpen(false)}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/account/login"
                className="block px-4 py-2 text-sm hover:text-base-background-3"
                onClick={() => setMenuOpen(false)}
              >
                Contact Us
              </Link>
            </li>
            <li>
              <LanguageSelection />
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
}

export default DesktopHeader;
