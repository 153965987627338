import React, { useState } from "react";
import Arrow from '../assets/icons/ChevronDown';
const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className=" w-full">
      <h5
        className="flex justify-between items-center text-[25px] cursor-pointer"
        onClick={toggleAccordion}
      >
        {title}
        <span
          className={`transform transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        >
          <Arrow />
        </span>
      </h5>
      <div
        className={`transition-[max-height] duration-300 overflow-hidden ${
          isOpen ? "max-h-full" : "max-h-0"
        }`}
      >
        <div className="mt-2">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
