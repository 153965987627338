const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
  const createPageNumbers = () => {
    const pages = [];
    if (totalPages <= 5) {
      for (let i = 0; i < totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage > 2) pages.push(0, '...');
      for (let i = Math.max(currentPage - 2, 0); i <= Math.min(currentPage + 2, totalPages - 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 3) pages.push('...', totalPages - 1);
    }
    return pages;
  };

  const pageNumbers = createPageNumbers();

  return (
    <div className="flex justify-center space-x-2">
      {pageNumbers.map((page, index) => (
        <button
          key={index}
          onClick={() => typeof page === 'number' && handlePageChange(page)}
          className={`px-4 py-2 border rounded-md ${
            currentPage === page ? 'bg-base-accent-1 text-white' : ''
          }`}
          disabled={typeof page !== 'number'}
        >
          {typeof page === 'number' ? page + 1 : page}
        </button>
      ))}
    </div>
  );
};
export default Pagination;