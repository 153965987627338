import { useTranslation } from "react-i18next";

function CategoryBox({src,name}) {
  return (
    <div className='flex flex-col'>
    <img src={src} className='w-full  md:w-[300px] md:h-[300px] object-cover transition-transform duration-500 ease-in-out hover:scale-105 cursor-pointer' alt='img' />
    <p className='text-[18px] md:text-[20px] mt-5 text-center text-base-accent-1'>{name}</p>
    
    </div>
  )
}

export default CategoryBox