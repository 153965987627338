import React, { useState, useEffect } from 'react';
import getToken from '../../utils/getToken';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddProduct = () => {
  const [product, setProduct] = useState({
    id: 0,
    price: 0,
    stockQuantity: 0,
    productCategoryId: 0,
    images: [],
    active: true,
    translations: [
      { id: 1, languageCode: 'en', name: '', description: '' }, // English
      { id: 2, languageCode: 'de', name: '', description: '' }  // German
    ]
  });

  const [categories, setCategories] = useState([]);
  const [productId, setProductId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const token = getToken();
      try {
        const response = await axios.get('http://85.215.136.36:8090/api/v1/product_categories', {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTranslationChange = (lang, field, value) => {
    const newTranslations = product.translations.map(translation => 
      translation.languageCode === lang ? { ...translation, [field]: value } : translation
    );
    setProduct((prev) => ({
      ...prev,
      translations: newTranslations,
    }));
  };

  const handleSubmit = async (e) => {
    console.log(product)
    e.preventDefault();
    const token = getToken();
    try {
      const res = await axios.post('http://85.215.136.36:8090/api/v1/products', product, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setProductId(res.data.id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (productId !== null) {
      navigate(`/admin/add-product/product-images/${productId}`);
    }
  }, [productId]);

  return (
    <div className='w-full h-full'>
      <div className="w-11/12 text-base-accent-1 mx-auto p-4">
        <h5 className="text-2xl font-semibold mb-4 text-center md:text-left">Add New Product</h5>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Translation sections */}
          {product.translations.map((translation) =>  (<div key={translation.languageCode}>
              <h5 className="text-lg font-semibold mt-4">{translation.languageCode === 'en' ? 'English' : 'German'} Translation</h5>
              <label className="block mt-5 text-sm font-medium">Name</label>
              <input
                type="text"
                value={translation.name}
                onChange={(e) => handleTranslationChange(translation.languageCode, 'name', e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
              <label className="block text-sm font-medium">Description</label>
              <textarea
                value={translation.description}
                onChange={(e) => handleTranslationChange(translation.languageCode, 'description', e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
          ))}
          <div>
            <label className="block text-sm font-medium">Price</label>
            <input
              type="number"
              name="price"
              value={product.price}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Stock Quantity</label>
            <input
              type="number"
              name="stockQuantity"
              value={product.stockQuantity}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
              />
            </div>
            <div>
  <label className="block text-sm font-medium">Status</label>
  <div className="flex items-center space-x-4 mt-1">
    <label className="flex items-center">
      <input
        type="radio"
        name="active"
        value="true"
        checked={product.active === true}
        onChange={(e) => setProduct((prev) => ({ ...prev, active: true }))}
        className="mr-2"
      />
      Active
    </label>
    <label className="flex items-center">
      <input
        type="radio"
        name="active"
        value="false"
        checked={product.active === false}
        onChange={(e) => setProduct((prev) => ({ ...prev, active: false }))}
        className="mr-2"
      />
      Inactive
    </label>
  </div>
</div>

            <div>
              <label className="block  text-sm font-medium">Product Category</label>
              <select
                name="productCategoryId"
                value={product.productCategoryId}
                onChange={handleChange}
                className="mt-1 block w-full border  bg-white border-gray-300 rounded-md p-2"
                required
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.translations &&category.translations.length>0&&category.translations[0].name}
                  </option>
                ))}
              </select>
            </div>
  
            
  
            <button type="submit" className="mt-4 bg-base-text text-white p-2 rounded">
              Save Product
            </button>
          </form>
        </div>
      </div>
    );
  };
  
  export default AddProduct;