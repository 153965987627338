import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 
import { useTranslation } from 'react-i18next';
import CategoryBox from './CategoryBox';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../../store/slices/categoriesSlice';




const Categories = () => {
    const {i18n} = useTranslation();
    const categories=useSelector(state=>state.categories.items)
    const status=useSelector(state=>state.categories.status)
    const dispatch=useDispatch()
    console.log({categories})
    useEffect(()=>{
      if (status==='idle')
      {
        dispatch(fetchCategories())
      }
    },[status,dispatch])
    

    // Load slides
    const {title,description,shopButton} = i18n.getDataByLanguage(i18n.language);
    
  
  return (
    <div className='w-11/12 m-auto mt-24 mb-12'>
                <h4 className='w-3/4 m-auto text-[40px] text-center text-base-accent-1 mb-5'> Feel Empowered To Forge Your Unique Style And Establish Your Own Principles In Pottery </h4>

      <Swiper
        slidesPerView={4}
        spaceBetween={32}
        pagination={{ clickable: true }}
        breakpoints={{
            0:{
                slidesPerView:1
            },
          768: {
            slidesPerView:2,
          },
          998:{
            slidesPerView:4,
          }
        }}
      >
        {categories.map((category) => (
          <SwiperSlide className='m-0 w-[100%]' key={category.id} >
            <Link key={category.id} to={`/categories/${category.id}`}><CategoryBox  src={category.categoryImage && category.categoryImage.imageUrl}  name={category.translations[0].name} /></Link>
            </SwiperSlide>
        ))}
      </Swiper>
      <div className=' mt-10 lg:mt-16'>
                <Link to='/' className={`text-base-accent-1 mx-auto  bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover   flex items-center  justify-center  gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[220px] text-center border border-1 border-base-accent-2`}>
                      view All
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="  bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                    </svg>
                </span>

            </Link>
        </div>
      
    </div>
  );
};



export default Categories;