
const translationsGridSlider = {
  en: {
    title:' Each Vessel Carries A Story; Every Craftsman, A Journey ',
    description:"Risus feugiat in ante metus dictum. Interdum varius sit amet mattis vulputate enim. Purus ut faucibus pulvinar elementum integer enim neque..",
    shopButton:"Shop Now"

  },
  de: {
    title:'Jedes Gefäß erzählt eine Geschichte, jeder Handwerker eine Reise',
    description:"Risus feugiat in ante metus dictum. Interdum varius sit amet mattis vulputate enim. Purus ut faucibus pulvinar elementum integer enim neque.",
    shopButton:"Jetzt kaufen"
  }
};

export default translationsGridSlider;