import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 

import img1 from '../../assets/images/home-grid-image-01.jpg';
import img2 from '../../assets/images/home-grid-image-02.jpg';
import img3 from '../../assets/images/home-grid-image-03.jpg';
import img4 from '../../assets/images/home-grid-image-01.jpg';
import img5 from '../../assets/images/home-grid-image-02.jpg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowRight from '../../assets/icons/ArrowRight';


const images = [
  { src: img1,id:1 },
  { src: img2,id:2 }, 
  { src: img3,id:3 },
  { src: img4,id:4 },
  { src: img5,id:5 },
];


const GridSlider = () => {
    const {i18n} = useTranslation();

    // Load slides
    const {title,description,shopButton} = i18n.getDataByLanguage(i18n.language);
  
  return (
    <div className="slider-container">
      <Swiper
        slidesPerView={3}
        spaceBetween={0}
        pagination={{ clickable: true }}
        breakpoints={{
            0:{
                slidesPerView:1
            },
          768: {
            slidesPerView:2,
          },
          998:{
            slidesPerView:3,
          }
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide className='m-0 w-[100%]' key={index} >
            <img src={image.src} className='w-full ' alt='img' />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="text-overlay">
        <div className='w-11/12 md:w-7/12 m-auto'>
        <h3 className='text-[24px] leading-[3rem] md:text-[40px] text-white'>{title}</h3>
        <p className='text-white mt-5'>{description}</p>
        <div className='mt-5'>
                <Link to='/' className={`text-base-accent-1 m-auto  bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover   flex items-center  justify-center  gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[220px] text-center border border-1 border-base-accent-2`}>
                {shopButton}
                <ArrowRight  />

            </Link>
        </div>
      </div>
        </div>
    </div>
  );
};



export default GridSlider;