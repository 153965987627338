import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import addTranslations from '../../utils/convertToi18nFormat'; // مسیر به تابع addTranslations
import ProductshoppingCard from '../shopping/ProductshoppingCard';

const ProductsList = ({ products }) => {
  console.log({products})
  const { i18n } = useTranslation();

  // Add translations to i18next on mount
  useEffect(() => {
    addTranslations(products, i18n);
  }, [products, i18n]);

  const { titleAbove, descriptionAbove } = i18n.getDataByLanguage(i18n.language);

  return (
    <div className='py-24'>
      <div className='text-center flex flex-col gap-1 w-10/12 m-auto md:w-full pb-8'>
        <h2 className='text-base-accent-1 text-[32px] md:text-[40px]'> {titleAbove} </h2>
        <p className='text-base-accent-1'>{descriptionAbove}</p>
      </div>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-11/12 gap-8 m-auto'>
        {products.length > 0 && products?.map((item) => (
          <ProductshoppingCard 
            product={item} 
            key={item.id} 
            productName={i18n.t(`product:productName-${item.id}`, { defaultValue: item.name })}    
          />
        ))}
      </div>
    </div>
  );
};

export default ProductsList;
