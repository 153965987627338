import React from 'react'

function ProductImages({product,setSelectedImage,selectedImage}) {
  return (
    <div className="flex w-full md:w-1/2 lg:w-5/12 flex-col lg:flex-row gap-4">
    {/* Main Image */}
    <div className="relative w-full  lg:w-10/12 h-[400px]     overflow-hidden border border-gray-200 ">
      <img
        src={selectedImage} // Display the selected image
        alt={product.translations && product.translations?.length && product.translations[0].name}
        className="w-full h-full object-cover  transition-transform duration-300 hover:scale-110" // Zoom effect on hover
      />
    </div>

    {/* Thumbnails */}
    <div className="flex flex-row lg:flex-col gap-2">
      {product.images && product.images?.length > 0 &&
        product.images.slice(0, 4).map((item, index) => (
          <img
            key={index}
            src={item.imageUrl} // Thumbnail image source
            alt={`Thumbnail ${index}`} // Thumbnail alt text
            onClick={() => setSelectedImage(item.imageUrl)} // Update the main image when a thumbnail is clicked
            className={`w-24 h-24 object-cover cursor-pointer border  border[1px] py-[2px] ${
              selectedImage === item.imageUrl ? 'border-gray-600' : 'border-transparent' // Highlight the selected thumbnail
            }`}
          />
        ))}
    </div>
  </div>

  )
}

export default ProductImages