import React from "react";
import formatPrice from "../../utils/formatPrice";
import Plus from "../../assets/icons/Plus";
import Minus from "../../assets/icons/Minus";
import QuantityControl from "../shopping/ProductDetails/QuantityControl";

function CartProducts({
  products,
  handleIncrease,
  handleDecrease,
  
}) {
  return (
    <>
      {products.products?.length ? (
        products.products.map((product) => (
          <div
            key={product.id}
            className="flex justify-between gap-2 mt-10 items-start px-5 border-b pb-4"
          >
            {/* تصویر و اطلاعات محصول */}
            <div className="flex gap-4 items-center">
              <img
                className="w-[100px] h-[100px] rounded-[3px] object-cover"
                src={product.images?.length ? product.images[0].imageUrl : ""}
                alt={
                  product.translations?.length
                    ? product.translations[0].name
                    : "Product Image"
                }
              />
              <div className="flex flex-col gap-3">
                <p className="text-[13px] text-base-accent-1 font-semibold">
                  {product.translations?.length
                    ? product.translations[0].name
                    : "Product Name"}
                </p>
                <p className="text-[13px] text-base-accent-1">
                  Rs. {formatPrice(product.price)}
                </p>
                 {/* کنترل تعداد */}
            <div className="flex flex-col items-center gap-3">
              <QuantityControl
              product={product}
                quantity={product.quantity}
                handleIncrease={() => handleIncrease(product)}
                handleDecrease={() => handleDecrease(product)}
              />
             
            </div>
              </div>
              
            </div>

           

            
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500 mt-10">Your cart is empty.</p>
      )}
    </>
  );
}

export default CartProducts;
