import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/" replace />;
};

export default ProtectedRoute;

//check role
{/*const ProtectedRoute = ({ children, role }) => {
    const { isAuthenticated, userRole } = useSelector((state) => state.auth);
  
    if (!isAuthenticated) {
      return <Navigate to="/account/login" replace />;
    }
  
    if (role && userRole !== role) {
      return <Navigate to="/" replace />; // دسترسی غیرمجاز
    }
  
    return children;
  };*/}
  