import React from "react";
import { useSelector } from "react-redux";

function UserPannel() {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="flex  ">
      <form className="w-full  bg-white p-6">
        <h6 className="text-[15px] md:text-[25px]  border-l-[10px] border-base-accent-1 text-base-accent-1 bg-gray-100 mb-4 p-4 ">
        Account details
        </h6>

       <div className="flex w-full flex-col md:flex-row md:gap-5  mt-10">  <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            First Name
          </label>
          <input
            type="text"
            value={user?.name || ""}
            readOnly
            className="w-full outline-none px-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Last Name
          </label>
          <input
            type="text"
            value={user?.familyName || ""}
            readOnly
            className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Email
          </label>
          <input
            type="email"
            value={user?.email || ""}
            readOnly
            className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        </div>

       

     
      </form>
    </div>
  );
}

export default UserPannel;
