import Trash from '../../assets/icons/Trash';

const AddressItem = ({
  address,
  isSelected,
  onSelect,
  onDelete,
  disableDelete,
  showRadio,
}) => {
  return (
    <div onClick={onSelect}
      className={`p-4 hover:bg-gray-100 duration-300  border rounded-md flex flex-col gap-2 ${
        isSelected ? "bg-gray-100" : "bg-white"
      }`}
    >
      
      <div className="relative flex items-center justify-end mb-2">
        <button
          disabled={disableDelete}
          onClick={onDelete}
          className="disabled:text-gray-400 text-red-500 hover:text-red-700 absolute right-0 top-0"
        >
          <Trash />
        </button>
      </div>

      {/* بخش اطلاعات آدرس */}
      <div className="text-base-accent-1 flex flex-col gap-1 break-words">
        <p>
          <span className="font-semibold">Street:</span> {address.street}
        </p>
        <p>
          <span className="font-semibold">City:</span> {address.city}, {address.state}
        </p>
        <p>
          <span className="font-semibold">Postal Code:</span> {address.postalCode}
        </p>
        <p>
          <span className="font-semibold">Country:</span> {address.country}
        </p>
      </div>
    </div>
  );
};

export default AddressItem;
