const BoxFilterDesktop = ({ localFilters, setLocalFilters, handleApplyFilters }) => {
  // Make sure handleApplyFilters is a function
  if (typeof handleApplyFilters !== 'function') {
    console.error('handleApplyFilters is not a function');
  }
  console.log(handleApplyFilters)

  return (
    <div className="hidden lg:block bg-white border border-gray-200 rounded-md shadow-md p-4">
      <h3 className="text-lg font-semibold mb-4">Filters</h3>

      {/* Price Range Filter */}
      <div className="mb-6">
        <span className="text-sm font-medium">Price</span>
        <div className="mt-2">
          <div className="flex space-x-2">
            <input
              type="number"
              placeholder="Min"
              min="0"
              inputMode="numeric"
              value={localFilters.minPrice || ''}
              onChange={(e) =>
                setLocalFilters((prev) => ({
                  ...prev,
                  minPrice: e.target.value ? Math.max(0, e.target.value) : '',
                }))
              }
              className="border outline-none border-gray-300 rounded p-2 w-full"
            />
            <input
              type="number"
              placeholder="Max"
              min="0"
              inputMode="numeric"
              value={localFilters.maxPrice || ''}
              onChange={(e) =>
                setLocalFilters((prev) => ({
                  ...prev,
                  maxPrice: e.target.value ? Math.max(0, e.target.value) : '',
                }))
              }
              className="border outline-none rounded p-2 w-full"
            />
          </div>
        </div>
      </div>

      {/* Sort Filter */}
      <div className="mb-6">
        <span className="text-sm font-medium">Sort</span>
        <div className="mt-2">
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="sortOrder"
              value="asc"
              checked={localFilters.sortOrder === 'asc'}
              onChange={() =>
                setLocalFilters((prev) => ({ ...prev, sortOrder: 'asc' }))
              }
              className="cursor-pointer outline-none"
            />
            <span>Ascending</span>
          </label>
          <label className="flex items-center space-x-2 mt-2">
            <input
              type="radio"
              name="sortOrder"
              value="desc"
              checked={localFilters.sortOrder === 'desc'}
              onChange={() =>
                setLocalFilters((prev) => ({ ...prev, sortOrder: 'desc' }))
              }
              className="cursor-pointer outline-none"
            />
            <span>Descending</span>
          </label>
        </div>
      </div>

      {/* Search Input */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search products"
          value={localFilters.search || ''}
          onChange={(e) =>
            setLocalFilters((prev) => ({ ...prev, search: e.target.value }))
          }
          className="border border-gray-300 outline-none rounded p-2 w-full"
        />
      </div>

      <button
        onClick={() => {
          if (typeof handleApplyFilters === 'function') {
            handleApplyFilters(localFilters); // Apply filters if it's a function
          } else {
            console.error(typeof handleApplyFilters);
          }
        }}
        className="bg-base-accent-1 text-white w-fit py-2 px-4 rounded"
      >
        Apply
      </button>
    </div>
  );
};

export default BoxFilterDesktop;
