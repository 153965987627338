import React, { useState, useEffect } from 'react';
import axios from 'axios';
import getToken from '../../utils/getToken';
import AllCategories from '../../components/AdminPannel/AllCategoriesPannel';

const CategoryManager = () => {
    const [categories, setCategories] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState(null);
 
    const [newCategory, setNewCategory] = useState({
        translations: [
            {
                languageCode: 'en', // English translation
                name: '',
                description: ''
            },
            {
                languageCode: 'de', // German translation
                name: '',
                description: ''
            }
        ]
    });
    const [categoryId, setCategoryId] = useState(null); // To store the created category ID
    const [imageFile, setImageFile] = useState(null); // To store the image file
    const [uploadSuccess, setUploadSuccess] = useState(false);

   
    const handleTranslationChange = (lang, field, value) => {
        const newTranslations = newCategory.translations.map(translation => {
            if (translation.languageCode === lang) {
                return { ...translation, [field]: value };
            }
            return translation;
        });

        setNewCategory((prev) => ({
            ...prev,
            translations: newTranslations,
        }));
    };

    const handleAddCategorySubmit = async (e) => {
        e.preventDefault();
        const token = getToken();
        try {
            const response = await axios.post('http://85.215.136.36:8090/api/v1/product_categories', newCategory, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setCategoryId(response.data.id); // Save the ID of the created category
            setCategories([...categories, response.data]);
        } catch (error) {
            console.error('Error adding category:', error);
        }
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleUploadImage = async (e) => {
        e.preventDefault();
        if (!imageFile || !categoryId) return;
        console.log({categoryId})
        const token = getToken();
        const formData = new FormData();
        formData.append('file', imageFile);

        try {
            await axios.post(`http://85.215.136.36:8090/api/v1/category_images?categoryId=${categoryId}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            });
            setUploadSuccess(true);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };
   

    
    return (
        <div className="h-full w-11/12 m-auto">
            <div className=" p-2 text-base-accent-1">
                <h5 className="text-2xl font-semibold text-center md:text-left mb-4">Manage Categories</h5>

                {/* Category List */}
                <AllCategories categories={categories} setCategories={setCategories} />

                {/* Add New Category Form */}
                {!categoryId && (
                    <>
                        <h5 className="mt-10 mb-5 font-bold">Add New Category</h5>
                        <form onSubmit={handleAddCategorySubmit} className="mb-4 space-y-4">
                            {/* English Translation Section */}
                            <h2 className="text-lg font-semibold mt-4">English Translation</h2>
                            <div>
                                <label className="block text-sm font-medium">Name</label>
                                <input
                                    type="text"
                                    value={newCategory.translations.find(t => t.languageCode === 'en').name}
                                    onChange={(e) => handleTranslationChange('en', 'name', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                                />
                                <label className="block text-sm font-medium">Description</label>
                                <textarea
                                    value={newCategory.translations.find(t => t.languageCode === 'en').description}
                                    onChange={(e) => handleTranslationChange('en', 'description', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                                />
                            </div>

                            {/* German Translation Section */}
                            <h2 className="text-lg font-semibold mt-4">German Translation</h2>
                            <div>
                                <label className="block text-sm font-medium">Name</label>
                                <input
                                    type="text"
                                    value={newCategory.translations.find(t => t.languageCode === 'de').name}
                                    onChange={(e) => handleTranslationChange('de', 'name', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                                />
                                <label className="block text-sm font-medium">Description</label>
                                <textarea
                                    value={newCategory.translations.find(t => t.languageCode === 'de').description}
                                    onChange={(e) => handleTranslationChange('de', 'description', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                                />
                            </div>

                            <button type="submit" className="mt-4 bg-base-accent-1 text-white p-2 rounded">
                                Add New Category
                            </button>
                        </form>
                    </>
                )}

                {/* Image Upload Form */}
                {categoryId && (
                    <div className="mt-10">
                        <h5 className="font-bold">Upload Image for Category</h5>
                        <form onSubmit={handleUploadImage} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium">Select Image</label>
                                <input
                                    type="file"
                                    onChange={handleImageChange}
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                                    required
                                />
                            </div>
                            <button type="submit" className="mt-4 bg-blue-600 text-white p-2 rounded">
                                Upload Image
                            </button>
                        </form>

                        {uploadSuccess && (
                            <p className="text-green-600 mt-2">Image uploaded successfully!</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CategoryManager;
