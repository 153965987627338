import React from 'react';
import AddressItem from './AddressItem';

const AddressList = ({ addresses, selectedAddress, onSelect, onDelete, disableDelete, type }) => {
  if (addresses.length === 0) {
    return <p>You don't have an address yet! Enter your address to place an order.</p>;
  }

  return (
    <div className="my-6 space-y-4">
      {addresses.map((address) => (
        <AddressItem
          key={address.id}
          address={address}
          isSelected={selectedAddress === address.id}
          onSelect={() => onSelect(address.id)}
          onDelete={() => onDelete(address.id)}
          disableDelete={disableDelete}
          showRadio={type !== 'pannel'}
        />
      ))}
    </div>
  );
};

export default AddressList;
