import React from 'react'
import { Link } from 'react-router-dom'
import ArrowRight from '../../../assets/icons/ArrowRight'

function Buttons() {
  return (
    <div>
        
            <Link to='/cart' className={`text-base-accent-1 mt-4 m-auto  bg-white hover:text-white hover:bg-base-accent-1  tracking-[0.1rem] duration-500 button_hover   flex items-center  justify-center  gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-full text-center border border-1 border-base-accent-2`}>
                View Cart
                <span>
                    <ArrowRight />
                </span>

            </Link>
    </div>
  )
}

export default Buttons