

import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import { showInfo } from '../../utils/toastUtils';
const CanvasCaptcha = ({ setIsVerified, isVerified, setCaptchaMessageShown }) => {
    const canvasRef = useRef(null);
    const [captchaCode, setCaptchaCode] = useState('');
    const [userInput, setUserInput] = useState('');
    const toast = useRef(null);
  
    const { i18n } = useTranslation();
    const { ReloadCaptcha, checkCaptch, successCaptch, errorCaptch } = i18n.getDataByLanguage(i18n.language);
  
    const generateCaptchaCode = () => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let code = '';
      for (let i = 0; i < 6; i++) {
        code += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return code;
    };
  
    const drawCaptcha = (code) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#f0f0f0';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.font = '30px Arial';
      ctx.fillStyle = '#333';
      const spacing = 15;
      for (let i = 0; i < code.length; i++) {
        const x = spacing + i * 30;
        const y = Math.random() * 20 + 30;
        const rotation = (Math.random() - 0.5) * 0.3;
        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(rotation);
        ctx.fillText(code[i], 0, 0);
        ctx.restore();
      }
      for (let i = 0; i < 30; i++) {
        ctx.beginPath();
        ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.strokeStyle = `rgba(0, 0, 0, ${Math.random()})`;
        ctx.stroke();
      }
    };
  
    useEffect(() => {
      const code = generateCaptchaCode();
      setCaptchaCode(code);
      drawCaptcha(code);
    }, []);
  
    const handleRefreshCaptcha = () => {
      const code = generateCaptchaCode();
      setCaptchaCode(code);
      drawCaptcha(code);
      setIsVerified(false);
      setUserInput('');
    };
  
    const handleVerifyCaptcha = () => {
      setCaptchaMessageShown(true); // نشان دادن پیام CAPTCHA
      if (userInput === captchaCode) {
        setIsVerified(true);
        showInfo(toast, 'success', 'Success', successCaptch);
      } else {
        setIsVerified(false);
        showInfo(toast, 'error', 'Error', errorCaptch);
        handleRefreshCaptcha();
      }
    };
  
    return (
      <div className="text-center w-[100%] font-sans">
        <Toast className="z-50" ref={toast} />
        <div className="flex gap-5">
          <canvas
            ref={canvasRef}
            width={200}
            height={50}
            className="border rounded-[30px] border-gray-300 my-4"
          ></canvas>
          <button
            onClick={handleRefreshCaptcha}
            className="my-4 px-4 py-2 text-base-accent-1 rounded-[30px] duration-300"
          >
            {ReloadCaptcha}
          </button>
        </div>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Enter the code"
          className="block w-full p-2 border rounded-[30px] outline-none text-center text-lg"
        />
        <button
          onClick={handleVerifyCaptcha}
          className={`mt-4 w-full px-4 py-2 rounded-[30px] ${
            isVerified ? 'bg-green-500' : 'bg-base-accent-1'
          } text-white hover:bg-opacity-90`}
        >
          {checkCaptch}
        </button>
      </div>
    );
  };
  
  export default CanvasCaptcha;
  