import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice'
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next'; // import useTranslation for i18n

function ProductshoppingCard({ product, cols = 3 }) {
  const { i18n } = useTranslation(); // i18n instance to handle translations
  
  return (
    <Link to={`/products/product-details/${product.id}`}>
      <div className={`${cols === 2 && "md:w-10/12" } mt-8 md:mt-0  relative group overflow-hidden`}>
        <LazyLoad offset={20}>
          <img 
            src={product.images.length && product.images[0].imageUrl} 
            alt={i18n.t(`product:productName-${product.id}`, { defaultValue: product.translations[0]?.name })} // Using i18n translation for the product name
            className={`object-cover w-full h-[350px]  ${cols === 0 && "lg:w-full"} ${cols === 2 && "lg:w-[400px] lg:h-[400px]"} ${cols === 3 && "lg:w-[277px] lg:h-[277px]"} ${cols === 4 && "lg:w-[200px] lg:h-[200px]"} transition-transform duration-500 ease-in-out group-hover:scale-110`}  
          />
        </LazyLoad>

        <div className='text-center flex mt-4 flex-col gap-1'>
          <p className='text-base-accent-1'>
            Rs. {formatPrice(product.price)} {/* Displaying the price of the product */}
          </p>
          <p className='text-base-accent-1 text-[18px] hover:text-base-background-3 duration-500'>
            {i18n.t(`product:productName-${product.id}`, { defaultValue: product.translations[0]?.name })} {/* Using translation */}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default ProductshoppingCard;
