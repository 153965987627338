import React from 'react';
import BannerShop from '../../components/banner/bannerShop';
import { useSelector } from 'react-redux';
import ShoppingCartCard from '../../components/UserShoppingCart/ShoppingCartCard';
import { Link } from 'react-router-dom';
import ArrowRight from '../../assets/icons/ArrowRight';
import CheckOutBtn from '../../components/cart/Buttons/CheckOutBtn';

function UserShoppigCart() {
  const { products, totalCost } = useSelector(state => state.cart);

  return (
    <div>
      <BannerShop title="Shopping Cart" />
      <div className="my-10 w-11/12 m-auto">
        

        {/* Table for larger screens */}
        <div className="hidden md:block">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="border-b text-base-accent-1">
                <th className="text-left py-3">Product</th>
                <th className="text-left py-3">Quantity</th>
                <th className="text-left py-3">Total</th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <ShoppingCartCard product={product} />
              ))}
            </tbody>
          </table>
        </div>

        {/* For small screens */}
        <div className="block md:hidden">
          <p className="text-base-accent-1 text-center text-lg font-medium">Products</p>
          <div className="space-y-4 mt-5">
            {products.map(product => (
              <div key={product.id} className="border-b pb-3">
                <ShoppingCartCard product={product} />
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center my-8">
          <div className="text-right">
            <Link
              to="/products"
              className={`text-base-accent-1 mx-auto md:mx-0 bg-[rgba(0,0,0,0)] hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[300px] text-center border border-1 border-base-accent-2`}
            >
              Continue Shopping
              <span>
                <ArrowRight />
              </span>
            </Link>
          </div>
        </div>

        {/* Total cost */}
        <div className="mt-10 md:mt-20 flex flex-col items-center md:items-end md:text-right">
          <span className="text-lg text-base-accent-1">Sub Total: Rs.{totalCost.toFixed(2)} </span>
          <span className="w-[300px] mr-0">
            <CheckOutBtn />
          </span>
        </div>
      </div>
    </div>
  );
}

export default UserShoppigCart;
