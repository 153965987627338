import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getToken from "../../utils/getToken";

function EditProductImage() {
  const { id } = useParams(); // Product ID
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null); // State for selected file
  const [isLoading, setIsLoading] = useState(false); // State for loading
  const token = getToken();

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await axios.get(`http://85.215.136.36:8090/api/v1/products/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setImages(res.data.images);
      } catch (err) {
        console.error("Error fetching product images:", err);
      }
    };
    if (id) getProduct();
  }, [id,isLoading]);

  // Delete image by image ID
  const deleteHandler = async (imageId) => {
    setIsLoading(true); // Disable buttons
    try {
      await axios.delete(`http://85.215.136.36:8090/api/v1/product_images/${imageId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update images state
      setImages((prevImages) => prevImages.filter((img) => img.id !== imageId));
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setIsLoading(false); // Re-enable buttons
    }
  };

  // Add new image for the product
  const handleAddImage = async () => {
    if (!selectedFile) return;

    setIsLoading(true); // Disable buttons
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `http://85.215.136.36:8090/api/v1/product_images?productId=${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setImages((prevImages) => [...prevImages, response.data]);
      setSelectedFile(null); // Clear the selected file after upload
    } catch (error) {
      console.error("Error adding image:", error);
    } finally {
      setIsLoading(false); // Re-enable buttons
    }
  };

  // Handle file change for adding a new image
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file); // Store selected file in state
  };
  
  return (
   <div className="w-11/12 mb-5 m-auto">
      <h5 className="text-xl text-base-accent-1 mb-4">Product Photos</h5>

      {images.length>0 ?<div className="flex flex-wrap my-10 gap-4">
        {images.length > 0 &&
          images.map((image) => (
            <div key={image.id} className="relative group">
              {/* Display image */}
              <img
                className="w-[140px] h-[140px] rounded-md"
                src={image.imageUrl}
                alt="Product"
              />

              {/* Delete button */}
              <button
                onClick={() => deleteHandler(image.id)}
                className={`absolute top-1 right-1 bg-red-500 text-white text-xs px-2 py-1 rounded ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading} // Disable when loading
              >
                Delete
              </button>
            </div>
          ))}

        
      </div>
      :<h3 className="mb-5 text-red-400">There are no photos.</h3> }
      {/* Button for adding a new image */}
      <div>
          <label className="add-new-image bg-base-accent-1  text-white px-4 py-2 rounded cursor-pointer">
            + Select New Image
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </label>
        </div>

      {/* Show "Send" button if a file is selected */}
      {selectedFile && (
        <div className="mt-6">
          <button
            onClick={handleAddImage}
            className={`bg-base-accent-1 text-white px-6 py-2 rounded ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading} // Disable when loading
          >
            Send
          </button>
        </div>
      )}
    </div>
  );
}

export default EditProductImage;
