import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { showInfo } from "../utils/toastUtils";
import api from "../services/apiServices";
import { useTranslation } from "react-i18next";
import axios from "axios";

function ResetPassword() {
  const { i18n } = useTranslation();

  const {
    title,
    newPassword,
    confirmNewPassword,
    buttonReset,
    successMessage,
    errorMessage,
  } = i18n.getDataByLanguage(i18n.language);

  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  const toast = useRef(null); // Toast reference

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (form.password !== form.confirmPassword) {
      showInfo(toast, "error", "Error", confirmNewPassword);
      return;
    }

    try {
      const token = new URLSearchParams(window.location.search).get("token"); // Extract token from URL
      if (!token) {
        throw new Error("Invalid or missing reset token.");
      }

      await axios.post("http://85.215.136.36:8090/api/v1/auth/reset-password", {
        token,
        newPassword: form.password,
      });

      showInfo(toast, "success", "Success", successMessage);
    } catch (error) {
    console.log(error)
      const error_ = error.response?.data?.message || errorMessage;
      showInfo(toast, "error", "Error", error_);
    }
  };

  return (
    <div className="text-base-accent-1 w-11/12 md:3/4 lg:w-1/2 m-auto flex-flex-col gap-5 items-center text-center my-20 justify-center">
      <Toast ref={toast} /> {/* Toast Component */}
      <h5 className="text-[30px]">{title}</h5>
      <form
        className="flex flex-col w-full m-auto md:w-11/12 gap-5 mt-6 text-[14px]"
        onSubmit={handleSubmit}
      >
        <input
          type="password"
          value={form.password}
          placeholder={newPassword}
          name="password"
          onChange={handleChange}
          className="py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border"
        />
        <input
          type="password"
          value={form.confirmPassword}
          placeholder={confirmNewPassword}
          name="confirmPassword"
          onChange={handleChange}
          className="py-3 outline-none px-6 rounded-[25px] border border-1 border-base-second_border"
        />
        <div className="mx-auto w-full">
          <button
            type="submit"
            className="text-base-accent-1 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[100%] md:w-[200px] md:mx-auto text-center border border-1 border-base-accent-2"
          >
            {buttonReset}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
