const translationsCaptch = {
    en: {
      ReloadCaptcha: "Reload captcha",
      checkCaptch: "check",
      successCaptch: "Captcha verified.",
      errorCaptch: "The captcha is incorrect. Please try again.",
    },
    de: {
        ReloadCaptcha: "Captcha neu laden",
        checkCaptch: "Rezension",
        successCaptch: "Captcha wurde verifiziert",
        errorCaptch: "Captcha ist falsch. Bitte versuchen Sie es erneut.",
    }
  };
  
  export default translationsCaptch;
  