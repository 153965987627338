import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters, setCurrentPage, fetchProducts } from '../../store/slices/productSlice';
import ShopFilters from '../../components/shopping/shopFilters/ShopFlters';
import GridLayout from '../../components/shopping/GridLayout/GridLayout';
import Loader from '../../components/Loader/Loader';
import ErrorMessage from '../../components/Error/ErrorMessage';
import Pagination from '../../components/shopping/pagination/Pagination';
import BannerAccount from '../../components/banner/BannerAccount';
import { useTranslation } from 'react-i18next';

const ProductsPage = () => {
  const dispatch = useDispatch();
  const { products, totalPages, loading, filters, currentPage, error } = useSelector(
    (state) => state.products
  );
  const { t } = useTranslation(); // Use the translation hook
  const loading2 = useSelector(state => state.shop);

  useEffect(() => {
    if (loading === 'idle') {
      dispatch(fetchProducts({ filters, currentPage, pageSize: 4 }));
    }
  }, [dispatch, filters, currentPage, loading]);

  const handleApplyFilters = (newFilters) => {
    dispatch(setFilters({ ...newFilters, page: 0 }));
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  if (loading === 'loading') return <Loader />;
  if (loading === 'error') return <ErrorMessage message={error} />;

  return (
    <>
      <BannerAccount title={t('productsPage.title')} />
      <div className="w-11/12 flex flex-col lg:flex-row mx-auto my-16">
        <div className="flex flex-col lg:flex-row gap-5">
          <div className="w-2/3 lg:w-1/4">
            <ShopFilters filters={filters} setFilters={setFilters} handleApplyFilters={handleApplyFilters} />
          </div>
          <div className="w-full lg:w-3/4">
            {products.length > 0 ? (
              <GridLayout products={products} />
            ) : (
              <div className="flex items-center justify-center text-gray-400">{t('productsPage.noProducts')}</div> // Use translation for no products found message
            )}
          </div>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default ProductsPage;
