import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function BottomMune
() {
    const [shopOpen, setShopOpen] = useState(false);
    const [pagesOpen, setPagesOpen] = useState(false);
    const isOpen=useSelector(state=>state.cartOpen.isOpen)
    console.log(isOpen)
    return (
      <div className={`fixed ${isOpen ?'z-49':"z-50"} bottom-0 left-0 right-0 bg-white border-t md:hidden`}>
        <div className="flex justify-around p-4">
          <Link to="/" className="text-gray-700">Home</Link>
          <Link to="#" className="text-gray-700" onClick={(e) => { e.preventDefault(); setShopOpen(!shopOpen); }}>Shop</Link>
          <Link to="#" className="text-gray-700" onClick={(e) => { e.preventDefault(); setPagesOpen(!pagesOpen); }}>Pages</Link>
          <Link to="/account/login" className="text-gray-700">Login</Link>
        </div>
      </div>
    );
  }
export default BottomMune;