import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import translation hook
import ProductshoppingCard from '../ProductshoppingCard';
import addTranslations from '../../../utils/convertToi18nFormat';

function GridLayout({ products }) {
  const { i18n } = useTranslation();

  // Add translations to i18next on mount
  useEffect(() => {
    addTranslations(products, i18n);
  }, [products, i18n]);

  const [columns, setColumns] = useState(3); 

  const handleGridChange = (columnCount) => {
    setColumns(columnCount);
  };

  return (
    <div className="container mx-auto md:py-10">
      <div className="hidden lg:flex justify-center gap-4 mb-6">
        <button
          onClick={() => handleGridChange(2)}
          className={`px-3 py-1 ${columns === 2 ? 'text-white rounded-[50%] bg-base-accent-1' : 'text-base-accent-1'} rounded`}
        >
          ||
        </button>
        <button
          onClick={() => handleGridChange(3)}
          className={`px-3 py-1 ${columns === 3 ? 'text-white rounded-[50%] bg-base-accent-1' : 'text-base-accent-1'} rounded`}
        >
          |||
        </button>
        <button
          onClick={() => handleGridChange(4)}
          className={`px-2 py-1 ${columns === 4 ? 'text-white rounded-[50%] bg-base-accent-1' : 'text-base-accent-1'} rounded`}
        >
          ||||
        </button>
      </div>

      <div className={`grid grid-cols-1 md:grid-cols-3 ${columns==2 && 'lg:grid-cols-2'} ${columns==3 && 'lg:grid-cols-3'} ${columns==4 && 'lg:grid-cols-4'} gap-4 transition-all duration-500 ease-in-out`}>
        {products.map((product) => (
          <ProductshoppingCard
            key={product.id}
            product={product}
            productName={i18n.t(`product:productName-${product.id}`, { defaultValue: product.name })}    
            cols={columns}
          />
        ))}
      </div>
    </div>
  );
}

export default GridLayout;
