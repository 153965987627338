import { Link } from "react-router-dom";
import leftImage from "../../assets/images/home-image-1.webp";
import rightImage1 from "../../assets/images/Rectangle_73.png";

import rightImage2 from "../../assets/images/Shop-13.webp";
import ArrowRight from "../../assets/icons/ArrowRight";

function TopProducts() {
  return (
    <div className="w-11/12  m-auto flex  flex-col md:flex-row ">
        <div className="md:w-7/12 lg:w-1/2 ">
          <img src={leftImage} className="h-9/12"  alt="product"/>
        </div>
        <div className="flex md:w-5/12 lg:w-1/2 items-center flex-col justify-center  ">
            <h4 className="text-xl md:text-3xl mt-10 lg:text-4xl text-center mb-8 w-11/12 lg:w-3/4  text-base-accent-1">Premium Porcelain Flower Vases & Pots </h4>
           <div className="flex w-full md:w-10/12 gap-7 ">
           <img  className=' w-1/2 md:w-full lg:w-1/2'src={rightImage1}  alt="product"/>
           <img src={rightImage2} className="w-1/2  md:w-1/2 md:hidden lg:block "  alt="product"/>
           </div>
           <div className=' my-8  lg:my-16'>
                <Link to='/products' className={`text-base-accent-1  bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover   flex items-center  justify-center  gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[220px] text-center border border-1 border-base-accent-2`}>
                shopNow
                <span>
                   <ArrowRight />
                </span>

            </Link>
        </div>
        </div>
    </div>
  )
}

export default TopProducts