import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import getToken from '../../utils/getToken';
import { Toast } from 'primereact/toast';
import { showInfo } from '../../utils/toastUtils';
import AddressList from './AddressList';
import AddressForm from './AddAdressForm';
import Loader from '../Loader/Loader';
import { useSelector } from 'react-redux';

function AddressSelectionPage({ type='default' }) {
  const [clickDelete, setClickDelete] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const token = getToken();
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const toast = useRef(null);
  const {user}=useSelector(state=>state.auth);
  console.log(user)

  useEffect(() => {
    const fetchAddresses = async () => {
      setIsLoadingFetch(true);
      try {
        const response = await axios.get(`http://85.215.136.36:8090/api/v1/addresses/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedAddresses = response.data;
        console.log({fetchedAddresses})
        setAddresses(fetchedAddresses);
        if (fetchedAddresses.length > 0 && !selectedAddress) {
          setSelectedAddress(fetchedAddresses[0].id);
        }
      } catch (error) {
        console.error('Error fetching addresses:', error);
      } finally {
        setIsLoadingFetch(false);
      }
    };

    fetchAddresses();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

   // Validate Postal Code before adding
   const validatePostalCode = async (postalCode) => {
    try {
      const response = await axios.get(`http://85.215.136.36:8090/api/v1/locations/${postalCode}`);
      return true; // If valid, return true
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showInfo(toast, 'error', 'Error', 'Invalid postal code. Please enter a valid one.');
        return false;
      } else {
        showInfo(toast, 'error', 'Error', 'An error occurred while validating the postal code. Please try again.');
        return false;
      }
    }
  };

  // Submit new address to API
  const handleAddAddress = async () => {
    if (addresses.length >= 3) {
      showInfo(toast, 'error', 'Error', 'You can only have up to 3 addresses.');
      return;
    }

    const isPostalValid = await validatePostalCode(newAddress.postalCode);
    if (!isPostalValid) {
      return;
    }

    try {
      const response = await axios.post(
        `http://85.215.136.36:8090/api/v1/addresses`,
        newAddress,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAddresses([...addresses, response.data]); // Add new address to list
      setSelectedAddress(response.data.id); // Automatically select the new address
      setIsAddingNew(false); // Close the form
      setNewAddress({ street: '', city: '', state: '', postalCode: '', country: '' }); // Reset form
    } catch (error) {
      console.error('Error adding address:', error);
    }
  };

  const handleDeleteAddress = async (id) => {
    if (addresses.length <= 1) {
      showInfo(toast, 'error', 'Error', 'You must have at least one address.');
      return;
    }
    setClickDelete(true);
    try {
      await axios.delete(`http://85.215.136.36:8090/api/v1/addresses/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClickDelete(false);
      const updatedAddresses = addresses.filter((address) => address.id !== id);
      setAddresses(updatedAddresses);
      if (id === selectedAddress && updatedAddresses.length > 0) {
        setSelectedAddress(updatedAddresses[0].id);
      }
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  const handleSubmit = async () => {
    if (!selectedAddress) {
      showInfo(toast, 'error', 'Error', 'You must select an address to submit.');
      return;
    }
    console.log(typeof selectedAddress)
    try {
      const res=await axios.post(`http://85.215.136.36:8090/api/v1/orders/user/${user.id}`, { addressId: +selectedAddress }
        ,{headers:{
          Authorization: `Bearer ${token}`,
        }}
      );
      showInfo(toast, 'success', 'Success', 'Order successfully submitted!');
      console.log(res)
    } catch (error) {
      console.error('Error submitting order:', error);
      showInfo(toast, 'error', 'Error', 'Failed to submit order.');
    }
  };

  if (isLoadingFetch) return <Loader />

  return (
    <div className={` ${type!=='panel' && 'p-10'  } w-full m-auto `}>
      <Toast className="z-50" ref={toast} />
      <h6 className={`text-[15px]  md:text-[25px] ${type!=='pannel' && 'hidden' }  border-l-[10px] border-base-accent-1 text-base-accent-1 bg-gray-100 mb-4 p-4`}>
        Your Addresses
        </h6>
      <h6 className={`text-xl w-3/4 text-base-accent-1 md:text-2xl ${type=='pannel' &&'hidden' }  my-5`}>Select or Add Address</h6>
      <div className=''>
      <AddressList
        addresses={addresses}
        selectedAddress={selectedAddress}
        onSelect={setSelectedAddress}
        onDelete={handleDeleteAddress}
        disableDelete={clickDelete}
        type={type}
      />
      </div>
      {!isAddingNew && addresses.length < 3 && (
        <button
          onClick={() => setIsAddingNew(true)}
          className="flex items-center mt-5 gap-2 rounded-[50%] bg-gray-200 text-base-accent-1 px-4 py-2  hover:bg-gray-400 duration-300"
        >
          <span className="text-xl ">+</span> 
        </button>
      )}
      {isAddingNew && (
        <AddressForm
          newAddress={newAddress}
          onChange={handleInputChange}
          onSave={handleAddAddress}
          onCancel={() => setIsAddingNew(false)}
        />
      )}
      <div className="my-5 ">
        <button
          onClick={handleSubmit}
          className={`bg-base-accent-1 disabled:bg-gray-300 w-full md:w-fit duration-300 text-white px-6 py-2 rounded-md hover:bg-base-background-3 ${type === 'pannel' && 'hidden'}`}
          disabled={!selectedAddress || addresses.length==0}

        >
          Submit Order
        </button>
      </div>
    </div>
  );
}

export default AddressSelectionPage;
