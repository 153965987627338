import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '../translations/mainpage/translationsSlides/sliderTranslations';
import translationHandmade from "../translations/mainpage/translationHandmade";
import translationsGridSlider from '../translations/mainpage/translatinGridSlider';
import translationsBanner from '../translations/Account/translationBanner';
import translationLogin from "../translations/Account/translationsLogin"
import translationsRegister from '../translations/Account/translationRegister';
import translationsForgotPassword from '../translations/Account/translationsForgotPassword';
import translationsResetPassword from '../translations/Account/translationsResetPssword';
import translationCaptch from '../translations/Account/translationCaptch';
import translationsCategoriesPage from '../translations/categories/translationCategoriesPage';

console.log('ss',translations.en.slides)
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en:{
        ...translations.en,
        ...translationHandmade.en,
        ...translationsGridSlider.en,
        ...translationsBanner.en,
        ...translationLogin.en,
        ...translationsRegister.en,
        ...translationsForgotPassword.en,
        ...translationsResetPassword.en,
        ...translationsCategoriesPage.en,
        ...translationCaptch.en
      },
      de:{
        ...translations.de,
        ...translationHandmade.de,
        ...translationsGridSlider.de,
        ...translationsBanner.de,
        ...translationLogin.de,
        ...translationsRegister.de,
        ...translationsForgotPassword.de,
        ...translationsResetPassword.de,
        ...translationsCategoriesPage.de,
        ...translationCaptch.de
      }
    }
    ,
    lng: 'en', ///Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  }, (err, t) => {
    if (err) {
      console.error('i18next initialization error:', err);
    } else {
      console.log('i18next initialized successfully');
    }
  });

export default i18n;