import React from 'react'

function ErrorMessage() {
  return (
    <div className='flex flex-col gap-5 w-[100vw] h-[100vh] z-50 items-center justify-center bg-white  '>
        <p className='text-[35px] md:text-[45px] text-red-600'>Error!</p>
        <h4 >Unfortunately, an issue has occurred. Please try again later.</h4>
    </div>
  )
}

export default ErrorMessage;