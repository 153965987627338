import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import productsReducer from './slices/productSlice'
import categoriesReducer from './slices/categoriesSlice';
import shopSlice from './slices/ProductWithCategoryaslice';
import cartSlice from './slices/cartSlice';
import cartOpenSlice from './slices/CartOpenSlice.js'
const store = configureStore({
  reducer: {
    auth: authSlice,
    products: productsReducer,
    categories: categoriesReducer,
    shop: shopSlice,
    cart:cartSlice,
    cartOpen:cartOpenSlice
  },
});

export default store;
