import React, { useState, useEffect, useRef } from 'react';

const Counter = ({ targetValue }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  // Determine if the component is on the page or not
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // When at least 50% of the element is in the user's view
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

 // Start counting when the element is on the page
  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount < targetValue) {
            return prevCount + 1;
          } else {
            clearInterval(interval);
            return targetValue;
          }
        });
      }, 100); 
      
      return () => clearInterval(interval);
    }
  }, [isVisible, targetValue]);

  return (
    <div className='w-full' ref={ref}>
      <p className='text-[3.7rem]'>{count}+</p>
    </div>
  );
};

const CounterSection = () => {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 sm:gap-20 lg:gap-30 w-3/4 items-center my-24 m-auto text-[24px]'> 
     
      
     <div className='text-center text-base-accent-1'>
        <Counter targetValue={10} /> 
        <p className='hover:text-base-background-3 text-[18px] duration-300 cursor-pointer'>Years In The Field</p>
     </div>
     <div className='text-center text-base-accent-1'>
        <Counter targetValue={15} />
         <p className='hover:text-base-background-3 text-[18px]  duration-300 cursor-pointer'>Products</p>
     </div>
     <div className='text-center text-base-accent-1'>
        <Counter targetValue={10}  />
        <p className='hover:text-base-background-3 text-[18px]  duration-300 cursor-pointer'>Happy Clients</p>
     </div>
      <div className='text-center text-base-accent-1'>
        <Counter targetValue={20}  />
        <p className='hover:text-base-background-3 duration-300 text-[18px]  cursor-pointer'>Branches</p>
      </div>
    </div>
  );
};

export default CounterSection;
