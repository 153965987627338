import { useSelector } from "react-redux";
import Minus from "../../../assets/icons/Minus";
import Plus from "../../../assets/icons/Plus";

const QuantityControl = ({ product, quantity, handleIncrease, handleDecrease }) => {
  const isLoading = useSelector((state) => state.cart.isLoading);

  const handleIncreaseWithStockCheck = (product) => {
    // اگر تعداد فعلی کمتر از موجودی انبار باشد، اجازه افزایش بده
    if (quantity < product.stockQuantity) {
      handleIncrease(product);
    }
  };

  return (
    <div className="border w-fit border-base-accent-1 flex gap-5 items-center rounded-[30px] relative">
      <button
        onClick={() => handleDecrease(product)}
        disabled={isLoading} // غیرفعال کردن دکمه‌ها هنگام بارگذاری
        className={`hover:bg-base-accent-1 p-3 w-full h-full rounded-l-[30px] duration-300 text-[30px] ${isLoading ? 'text-[rgba(255,255,255,0.5)] bg-base-accent-1/50 cursor-not-allowed' : ''}`}
      >
        <Minus />
      </button>
      <p className="py-2">{quantity}</p>
      <button
        onClick={() => handleIncreaseWithStockCheck(product)} // بررسی موجودی انبار قبل از افزایش
        disabled={isLoading || quantity >= product.stockQuantity} // اگر تعداد برابر یا بیشتر از موجودی انبار باشد، دکمه غیرفعال می‌شود
        className={`hover:bg-base-accent-1 p-3 w-full h-full rounded-r-[30px] duration-300 text-[30px] ${isLoading ? 'text-[rgba(255,255,255,0.5)] bg-base-accent-1/50 cursor-not-allowed' : ''} ${quantity >= product.stockQuantity ? 'bg-gray-300 cursor-not-allowed' : ''}`}
      >
        <Plus />
      </button>

      {isLoading && (
        <div className="absolute right-[-40px] animate-spin w-6 h-6 border-4 border-t-4 border-base-accent-1 rounded-full"></div>
      )}
    </div>
  );
};

export default QuantityControl;
