import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchProducts = createAsyncThunk(
  'shop/fetchProducts',  
  async ({ filters, currentPage, pageSize }) => {
    const params = {
      pageNo: currentPage,
      pageSize,
      sortBy: 'id',
      sortDir: filters.sortOrder ||'desc',
      ...(filters.minPrice && { minPrice: filters.minPrice }),
      ...(filters.maxPrice && { maxPrice: filters.maxPrice }),
      ...(filters.search && { search: filters.search }),
    };

    const queryString = new URLSearchParams(params).toString();
    console.log(queryString);  // Debugging the query string
    const response = await axios.get(
      `http://85.215.136.36:8090/api/v1/products?${queryString}`
    );
    console.log(queryString);  // Debugging the response
    console.log(response);  // Debugging the response


    return response.data;
  }
);

const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    products: [],
    totalPages: 0,
    loading: 'idle',
    filters: {
      minPrice: '',
      maxPrice: '',
      sortOrder: 'desc',
      search: '',
    },
    currentPage: 0,
    pageSize: 4,
  },
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
      state.loading = 'idle';  // Reset loading when filters change
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
      state.loading = 'idle';  // Reset loading when currentPage changes
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = 'loading';  // Set loading state to 'loading' when fetching starts
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.loading = 'success';  // Set loading to 'success' when data is fetched successfully
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.loading = 'error';  // Set loading to 'error' if the request fails
      });
  },
});

export const { setFilters, setCurrentPage, setPageSize } = shopSlice.actions;
export default shopSlice.reducer;
