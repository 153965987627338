import React from 'react'
import vector from "../../assets/images/Vector_2fa1784b-dad5-4714-b3a5-afd2b04c3f36.png";
import home_services_image from "../../assets/images/home-video-image-1.jpg";
import backgroundImageServices from "../../assets/images/Group_142367.png"
import { Link } from 'react-router-dom';
import ArrowRight from '../../assets/icons/ArrowRight';
function Services() {
  return (
    <div className='flex flex-col-reverse pb-5 md:pb-0  md:flex-row md:h-[600px] bg-fixed bg-center ' style={{backgroundImage:`url(${backgroundImageServices})`}}>
        <div className='w-full mt-10 text-center md:text-left md:mt-0 md:w-7/12 px-5 md:px-10  flex justify-center gap-1  flex-col'  >
            <p className='text-base-accent-1'>Unique Designs</p>
            <h6 className='text-[25px] w-full md:text-[40px]  text-base-accent-1'> Summer Clay Art Workshop </h6>
            <p className='md:w-10/12 leading-6  text-justify text-[17px] text-base-accent-1'>Pretium nibh ipsum consequat nisl vel pretium. Phasellus egestas tellus rutrum tellus pellentesque eu. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel.</p>
            <div className='grid mx-auto md:mx-0 grid-cols-1 mt-10 md:mt-4 gap-4 md:grid-cols-2 '>
                <div className='flex gap-2 items-center '>
                    <img src={vector} alt='*' className='w-[23px] h-[23px] ' />
                    <p className='text-base-accent-1 text-[18px]'>
                          Basic Techniques
                    </p>
                </div>
                <div className='flex gap-2 items-center '>
                    <img src={vector} alt='*' className='w-[23px] h-[23px] ' />
                    <p className='text-base-accent-1 text-[18px]'>
                          
                        Wheel Throwing

                    </p>
                </div>
                <div  className='flex gap-2 items-center '>
                    <img src={vector} alt='*'  className='w-[23px] h-[23px] '/>
                    <p className='text-base-accent-1 text-[18px]'> 
                        Decorating and Glazing
                    </p>
                </div>
                <div className='flex gap-2 items-center ' >
                    <img src={vector} alt='*'className='w-[23px] h-[23px] ' />
                    <p className='text-base-accent-1 text-[18px]'> 
                        Firing and Finishing
                    </p>
                </div>
            </div>
            <div className=' mt-6'>
                <Link to='/products' className={`text-base-accent-1 mx-auto md:mx-0 bg-[rgba(0,0,0,0)] hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover   flex items-center  justify-center  gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[220px] text-center border border-1 border-base-accent-2`}>
                      Shop Now
                <span>
                    <ArrowRight />
                </span>

            </Link>
        </div>
        </div>
        <div className='w-full md:w-5/12 '>
            <img src={home_services_image} className='h-full w-full' alt='home services image '/>
        </div>
    </div>
  )
}

export default Services