import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import { Toast } from 'primereact/toast';
import BannerAccount from "../components/banner/BannerAccount";
import { useTranslation } from "react-i18next";
import axios from "axios";

function ForgotPassword() {
    const toast = useRef(null);
    const [email,setEmail]=useState('')
    const { i18n } = useTranslation();
    const { resetPasswordTitle, resetPasswordMessage, emailPlaceholder, submitButton, cancelLink
        ,successMessage,
        errorEmptyEmail,
        errorSendFailure


     } = i18n.getDataByLanguage(i18n.language);


  const showInfo = (severity, summary, detail) => {
      toast.current.show({ severity, summary, detail, life: 3000 });
  };
  const sendEmail = async (event) => {
    event.preventDefault();
    if (!email) {
    // Display error message if input is empty
        showInfo('error', 'Error',errorEmptyEmail);
        return;
    }
    
    try {
        const res = await axios.post("http://85.215.136.36:8090/api/v1/auth/forgot-password", {email});
        const data = await res.json();
        console.log(data);
      // Display success message
        showInfo('success','success', successMessage);
    } catch (error) {
        console.log(error);
      // Display error message if an error occurs
        showInfo('error', 'Error', errorSendFailure);
    }
};
    return (
        <> 
            <div>
                <BannerAccount />
                <div className='text-base-accent-1 w-11/12 md:3/4 lg:w-1/2 m-auto flex flex-col gap-5 items-center text-center my-20 justify-center'>
                    <h5 className='text-[30px]'> {resetPasswordTitle} </h5>
                    <p> {resetPasswordMessage}</p>
                    <form className='flex flex-col w-full m-auto md:w-11/12 gap-5 mt-6 text-[14px]' onSubmit={sendEmail}>
                        <Toast className="z-50" ref={toast} />
                        <input
                            type="text"
                            value={email}
                            placeholder={emailPlaceholder}
                            
                            onChange={(event)=>setEmail(event.target.value)}
                            className='py-3 outline-none px-6 rounded-[25px] border border-1 border-[rgba(23,64,60,0.2)]'
                        />
                        
                        <div className='mx-auto w-full '>
                            <button className={`text-base-accent-1 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[100%] md:w-[130px] md:mx-auto text-center border border-1 border-base-accent-2`}>
                                {submitButton}
                            </button>
                        </div>
                    </form>
                 <div className="w-full text-center mt-2 text-[14px] md:text-[15px]">
                        <Link to='/account/login' className="underline text-base-accent-1">{cancelLink} </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;