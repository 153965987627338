import axios from "axios";
import { useEffect, useState } from "react";
import getToken from "../../utils/getToken";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";

function UserOrders() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setLoading] = useState(true); // ابتدا به true تغییر داده شد
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const token = getToken();
    const getOrders = async () => {
      try {
        const res = await axios.get(
          `http://85.215.136.36:8090/api/v1/orders/user/${user.id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setOrders(res.data);
      } catch (err) {
        console.error("Error fetching orders:", err);
      } finally {
        setLoading(false); // در هر صورت لودینگ تمام شود
      }
    };
    if (user.id) getOrders();
  }, [user.id]);

  if (isLoading) return <Loader />;

  return (
    <div className="container mx-auto px-4 py-8">
      <h6 className="text-[15px] md:text-[25px]  border-l-[10px] border-base-accent-1 text-base-accent-1 bg-gray-100 mb-4 p-4 ">
        Your Orders
        </h6>

      {orders.length === 0 ? (
        <div className="text-center text-gray-600">
          <p className="text-lg">You have not placed any orders yet.</p>
          
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {orders.map((order) => (
            <div
              key={order.id}
              className="bg-white shadow-md rounded-lg p-4 border border-gray-200"
            >
              <p className="text-lg font-semibold text-gray-800">
                Order #{order.id}
              </p>
              <p className="text-gray-600">
                Total: <span className="font-bold">$</span>
              </p>
              <p className="text-gray-600">
                Status:{" "}
                {/*<span
                  className={`font-bold ${
                    order.status === "completed"
                      ? "text-green-500"
                      : "text-yellow-500"
                  }`}
                >
                  {order.status}
                </span>*/}
              </p>
              {/*<ul className="mt-4">
                {order.items.map((item) => (
                  <li key={item.id} className="text-gray-600">
                    - {item.name} x {item.quantity}
                  </li>
                ))}
              </ul>*/}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserOrders;
