import { useState } from "react";

const AddToCartButton = ({product, handleAddToCart, isLoading, quantity }) => {
  console.log({isLoading})
  if (quantity > 0) return null; // اگر محصول در سبد باشد، نمایش ندهد
  return (
  <>
  {product.active==false || product.stockQuantity==0?
    <button onClick={()=>handleAddToCart(product)} disabled={true} className="p-10 hover:bg-white disabled:bg-gray-300 disabled: hover:text-base-accent-1 bg-gray-300 text-white duration-300 py-2  cursor-not-allowed rounded-[30px]">
    Sold Out
  </button>:
    <button onClick={()=>handleAddToCart(product)} disabled={isLoading} className="p-10 hover:bg-white hover:text-base-accent-1 bg-base-accent-1 text-white duration-300 py-2 border border-base-accent-1 rounded-[30px]">
    {isLoading ? "Adding..." : "Add to Cart"}
  </button>

  }
  </>
  );
};

export default AddToCartButton;
