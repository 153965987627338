import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SlideBox from './SlideBox';

const Slider = () => {
  const {i18n} = useTranslation();

  // Load slides
  const slides = i18n.getDataByLanguage(i18n.language).slides;

 


  return (
    <Swiper spaceBetween={50} slidesPerView={1}>
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <SlideBox title={slide.title} description={slide.description} button={slide.button} 
          bgImage={slide.bgImage} image={slide.image}/>
         
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;