import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ArrowRight from '../../../assets/icons/ArrowRight'
import { useSelector } from 'react-redux'

function CheckOutBtn() {
    const {isAuthenticated}=useSelector(state=>state.auth)
    const navigate=useNavigate()
    const clickHandler=()=>{
        if (isAuthenticated){
            navigate('/checkOut')

        }
    else{
        navigate('/account/login')
    }
    }
  return (
    <button onClick={clickHandler} className={`text-base-accent-1 m-auto mt-7  bg-white hover:text-white hover:bg-base-accent-1  tracking-[0.1rem] duration-500 button_hover   flex items-center  justify-center  gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-full text-center border border-1 border-base-accent-2`}>
                Check Out 
                <span>
                    <ArrowRight />
                </span>

            </button>
  )
}

export default CheckOutBtn