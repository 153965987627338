import { useEffect, useState } from "react";
import Filter from '../../../assets/icons/Filter';

// کامپوننت فیلتر برای موبایل
const BoxFilterMobile = ({ localFilters, setLocalFilters, handleApplyFilters }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
      return () => document.body.classList.remove('overflow-hidden');
    }, [isOpen]);
  
    return (
      <div className="lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex gap-2 items-center text-base-accent-1 py-2 relative"
        >
          <Filter />
          <span>Filters and Sort</span>
        </button>
        <hr className="border-gray-300 my-2" />
  
        {/* Overlay */}
        {isOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setIsOpen(false)}
          ></div>
        )}
  
        {/* Filter Drawer */}
        <div
          className={`fixed bg-white inset-y-0 right-0 transform transition-transform duration-300 z-50 ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
          } w-3/4 md:w-1/2 p-4 shadow-md`}
        >
          <button
            onClick={() => setIsOpen(false)}
            className="text-base-accent-1 text-sm font-medium mb-4"
          >
            X
          </button>
          <h3 className="text-lg font-semibold mb-4">Filters</h3>
  
          {/* Price Range Filter */}
          <div className="mb-6">
            <span className="text-sm font-medium">Price</span>
            <div className="mt-2">
              <div className="flex space-x-2">
                <input
                  type="number"
                  placeholder="Min"
                  min="0"
                  inputMode="numeric"
                  value={localFilters.minPrice || ''}
                  onChange={(e) =>
                    setLocalFilters((prev) => ({
                      ...prev,
                      minPrice: e.target.value ? Math.max(0, e.target.value) : '',
                    }))
                  }
                  className="border outline-none border-gray-300 rounded p-2 w-full"
                />
                <input
                  type="number"
                  placeholder="Max"
                  min="0"
                  inputMode="numeric"
                  value={localFilters.maxPrice || ''}
                  onChange={(e) =>
                    setLocalFilters((prev) => ({
                      ...prev,
                      maxPrice: e.target.value ? Math.max(0, e.target.value) : '',
                    }))
                  }
                  className="border outline-none rounded p-2 w-full"
                />
              </div>
            </div>
          </div>
  
          {/* Sort Filter */}
          <div className="mb-6">
            <span className="text-sm font-medium">Sort</span>
            <div className="mt-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="sortOrder"
                  value="asc"
                  checked={localFilters.sortOrder === 'asc'}
                  onChange={() =>
                    setLocalFilters((prev) => ({ ...prev, sortOrder: 'asc' }))
                  }
                  className="cursor-pointer outline-none"
                />
                <span>Ascending</span>
              </label>
              <label className="flex items-center space-x-2 mt-2">
                <input
                  type="radio"
                  name="sortOrder"
                  value="desc"
                  checked={localFilters.sortOrder === 'desc'}
                  onChange={() =>
                    setLocalFilters((prev) => ({ ...prev, sortOrder: 'desc' }))
                  }
                  className="cursor-pointer outline-none"
                />
                <span>Descending</span>
              </label>
            </div>
          </div>
  
          {/* Search Input */}
          <div className="mb-6">
            <input
              type="text"
              placeholder="Search products"
              value={localFilters.search || ''}
              onChange={(e) =>
                setLocalFilters((prev) => ({ ...prev, search: e.target.value }))
              }
              className="border border-gray-300 outline-none rounded p-2 w-full"
            />
          </div>
  
          <button
            onClick={() => {
              handleApplyFilters(localFilters)
              setIsOpen(false);
            }}
            className="bg-base-accent-1 text-white w-fit py-2 px-4 rounded"
          >
            Apply
          </button>
        </div>
      </div>
    );
  };
  
  export default BoxFilterMobile;