import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  fetchProducts } from '../store/slices/productSlice';
import Slider from '../components/mainpage/slider/slider';
import Products from '../components/mainpage/HandmadProducts';
import GridSlider from '../components/mainpage/GridSlider';
import '../i18next/i18n';
import NumberCounterSection from '../components/mainpage/NumberCounterSection';
import TopProducts from "../components/mainpage/TopProducts"
import Categories from '../components/mainpage/Categories/Categories';
import Services from '../components/mainpage/Services';
import Skills from '../components/mainpage/Skills';
import FirstOrderDiscount from '../components/mainpage/FirstOrderDiscount';
import ClientFeedback from '../components/ClientFeedBack';
import Loader from '../components/Loader/Loader';
import ErrorMessage from '../components/Error/ErrorMessage';
import { useParams } from 'react-router-dom';

const App = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const status = useSelector((state) => state.products.loading);
  const {pathname} =useParams()
  useEffect(() => {
    if (status === 'idle' ) {
      dispatch(fetchProducts({ filters:{}, currentPage:0, pageSize:4 }));    }
  }, [dispatch,pathname]);

  if (status === 'loading'  ) {
    return <Loader />; 
  }
  
  if (status=='error')
    return <ErrorMessage />

  return (
    <div className="App">
      <Slider />
    <Products products={products} />
      <GridSlider />
      <NumberCounterSection />
      <TopProducts />
      <Categories />
      <Services />
      <Skills />
      <FirstOrderDiscount />
      <ClientFeedback />
    </div>
  );
};

export default App;
