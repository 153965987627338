import React from 'react'

function FeedBackBox({item:{title,comment,profile,name,job}}) {
  return (
    <div className='flex flex-col w-11/12 m-auto my-10 md:px-10 justify-center h-full text-base-accent-1'>
        <h5 className='text-[24px] md:text-[30px] '>
            {title}
        </h5>
        <p className='mt-3 w-10/12 text-[14px] text-justify md:text-[18px]'>{comment}</p>
        <div className='flex  items-center mt-5 gap-3 '>
            <img className='rounded-[50%] w-[80px]' src={profile} alt='user profile' />
            <div >
                 <p className='font-bold text-[19px]'>{name}</p>
                <p className='text-[13px]'>{job}</p>
            </div>
        </div>

    </div>
  )
}

export default FeedBackBox