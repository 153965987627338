
const translationsBanner = {
  en: {
    title:"Account",
    home:"home"
  },
  de: {
   title:"Konto",
   home:"heim"
  }
};

export default translationsBanner;