import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import VerticalMenu from '../components/AdminPannel/VerticalMenu';
import Loader from '../components/Loader/Loader';
import ErrorMessage from '../components/Error/ErrorMessage';
import UserVerticalMenu from '../components/User-pannel/UserVerticalMenu';
import Cart from '../components/cart/Cart';

function Layout({ children }) {
  const status = useSelector((state) => state.shop.loading);
  const statusCategories=useSelector(state=>state.categories.status)
  const location = useLocation();
  const isAdminPanel = location.pathname.startsWith('/admin');
  const isUserPannel=location.pathname.startsWith('/user');
  const isOpen=useSelector(state=>state.cartOpen.isOpen)
  
   ////if (status=='error' || statusCategories=="error")
//return <ErrorMessage />
  return (
    <div className="font-Montserrat">
     
      {(status === 'loading' || statusCategories=="loading") && <Loader />}

      {status !== 'loading' && status !== 'error' && (
        <div className={`flex flex-col ${isAdminPanel || isUserPannel ? 'h-screen' : ''}`}>
          {/* Header */}
          {!isAdminPanel && !isUserPannel &&  <header>
            <Header />
          </header>}

          {/* Main Content */}
          <div className={`flex-grow flex ${isAdminPanel || isUserPannel ? '' : 'flex-col'}`}>
            {isAdminPanel && (
              <aside className='fixed md:relative '>
                {/* Vertical Menu */}
                <VerticalMenu />
              </aside>
            )}
            {isUserPannel && (
              <aside className="w-3/12 md:w-1/4">
                {/*User Vertical Menu */}
                <UserVerticalMenu />
              </aside>
            )}
            <main className={`${isUserPannel || isAdminPanel && 'w-full mt-5'}`}>
              {children}
            </main>
          </div>

          {/* Footer */}
          {!isAdminPanel && !isUserPannel && (
            <footer>
              <Footer />
            </footer>
          )}
        </div>
      )}
    </div>
  );
}

export default Layout;
