import { createSlice } from "@reduxjs/toolkit";
import getCount from "../../utils/getCount";
import getTotalCost from "../../utils/getTotalCost";
import axios from "axios";
import getToken from "../../utils/getToken";
import SetToStorage from "../../translations/mainpage/SetToLocalStorage";

const initialState = {
  products: JSON.parse(localStorage.getItem("products") || "[]"),
  count:+JSON.parse(localStorage.getItem("count") || "[]"), 
  totalCost: +JSON.parse(localStorage.getItem("totalCost") || "[]"),
  cartId: localStorage.getItem("cartId") || null,
  isLoading: false, // اضافه کردن وضعیت بارگذاری
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // شروع عملیات لودینگ
    SET_LOADING(state, action) {
      state.isLoading = action.payload;
    },
    ADD_TO_CART(state, action) {
      const existingProduct = state.products.find((p) => p.id === action.payload.id);
      if (existingProduct) {
        existingProduct.quantity++;
      } else {
        state.products.push({ ...action.payload, quantity: 1 });
      }
      state.count = getCount(state.products);
      state.totalCost = getTotalCost(state.products);
      SetToStorage(state.count,state.totalCost,state.products)  


    },
    INCREASE(state, action) {
      const product = state.products.find((p) => p.id === action.payload.id);
      if (product) {
        product.quantity++;
        state.count = getCount(state.products);
        state.totalCost = getTotalCost(state.products);
        SetToStorage(state.count,state.totalCost,state.products)       }
    },
    DECREASE(state, action) {
      const productIndex = state.products.findIndex((p) => p.id === action.payload.id);
      if (productIndex !== -1) {
        const product = state.products[productIndex];
        if (product.quantity > 1) {
          product.quantity--;
        } else {
          state.products.splice(productIndex, 1);
        }
        state.count = getCount(state.products);
        state.totalCost = getTotalCost(state.products);
        SetToStorage(state.count,state.totalCost,state.products)       }
    },
    REMOVE_ITEM(state, action) {
      state.products = state.products.filter((p) => p.id !== action.payload.id);
      state.count = getCount(state.products);
      state.totalCost = getTotalCost(state.products);
      SetToStorage(state.count,state.totalCost,state.products)   },
    SET_CART_ID(state, action) {
      state.cartId = action.payload;
      localStorage.setItem("cartId", action.payload);
    },
    RESET_CART(state) {
      state.products = [];
      state.count = 0;
      state.totalCost = 0;
      state.cartId = null;
      localStorage.removeItem("products");
      localStorage.removeItem("cartId");
    },
  },
});


export const syncCartWithServer = () => async (dispatch, getState) => {
  const { products, cartId } = getState().cart;
  const { isAuthenticated, user } = getState().auth;
  const token = getToken();

  if (isAuthenticated) {
    try {
      dispatch(SET_LOADING(true)); // شروع لودینگ

      let currentCartId = localStorage.getItem('cartId') || cartId || null;
console.log(currentCartId)
      // چک کردن وجود سبد خرید
      if (currentCartId==null) {
        try {
          // تلاش برای دریافت سبد خرید موجود
          const getResponse = await axios.get(
            `http://85.215.136.36:8090/api/v1/cart/user/${user.id}`
          );
          if (getResponse.data?.id) {
            currentCartId = getResponse.data.id;
            dispatch(SET_CART_ID(currentCartId));
          }
        } catch (error) {
          // اگر خطای 404 باشد، یک سبد خرید جدید بساز
          if (error.response?.status === 404) {
            const postResponse = await axios.post(
              `http://85.215.136.36:8090/api/v1/cart/user/${user.id}`,
              {
                "id": 0,
                "productId": 0,
                "quantity": 0,
                "price": 0
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );
            currentCartId = postResponse.data.id;
            dispatch(SET_CART_ID(currentCartId));
          } else {
            throw error; // سایر خطاها را پرتاب کن
          }
        }
      }

      // ارسال محصولات به سرور
      if(cartId){
      const res=await axios.put(
        `http://85.215.136.36:8090/api/v1/cart/user/${user.id}`,
        {
          id: +currentCartId,
          userId: user.id,
          cartItems: products.map((item) => ({
            productId: item.id,
            quantity: item.quantity,
            price: item.price,
          })),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(res)

      dispatch(SET_LOADING(false)); // پایان لودینگ
    }
    } catch (error) {
      dispatch(SET_LOADING(false)); // پایان لودینگ در صورت خطا
      console.error("Error syncing cart with server:", error);
    }
  }
};

export const { 
  ADD_TO_CART, 
  INCREASE, 
  DECREASE, 
  REMOVE_ITEM, 
  RESET_CART, 
  SET_CART_ID, 
  SET_LOADING 
} = cartSlice.actions;

export default cartSlice.reducer;
