
const translationsCategoriesPage = {
    en: {
      titlecategories:'All collections',
    },
    de: {
    titlecategories:'Alle Kollektionen',
      
    }
  };
  
  export default translationsCategoriesPage;