import React from 'react'
import skillicon from '../../assets/images/Vector_1.webp';
function SkillBox() {
  return (
    <div className='text-center flex flex-col cursor-grabbing gap-3 text-base-accent-1'>
        <img className='mx-auto w-[60px]' src={skillicon} alt='icon' />
        <h6 className='text-[25px]'>Slip Casting</h6>
        <p className='md:w-10/12 m-auto'>Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</p>
    </div>
  )
}

export default SkillBox