import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import getToken from '../../utils/getToken';
import { showInfo } from '../../utils/toastUtils';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';


const ProductManagement = () => {
    const [products, setProducts] = useState([]);
    const [loading,setLoading]=useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [message, setMessage] = useState('');
    const token = getToken();
    const toast = useRef(null); // Toast reference
    
    // Fetch products from API
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('http://85.215.136.36:8090/api/v1/products');
                setProducts(response.data.content);
                console.log(response.data.content)
                setLoading(true)
                
            } catch (error) {
              showInfo(toast, "error", "Error", error.message);

            }
        };

        fetchProducts();
    }, []);
    
   
    

    const handleDeleteProduct = async () => {
        
        try {
            await axios.delete(`http://85.215.136.36:8090/api/v1/products/${selectedProduct.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            //Filtering products to render and redisplay in the UI
            setProducts(products.filter(product => product.id !== selectedProduct.id));
            showInfo(toast, "success", "Success", 'Product deleted successfully!');
            setIsDeleting(false);
            setSelectedProduct(null);
        } catch (error) {
            console.error('Error deleting product:', error);
            showInfo(toast, "error", "Error", error.message);

        }
    };
    if (!loading)
        return <Loader />

    return (
       <div className='h-full w-full '> <div className="container w-11/12 mx-auto overflow-x-auto mb-5 ">
           <Toast ref={toast} /> {/* Toast Component */}
            <h5 className="text-xl md:text-2xl text-base-accent-1 my-8   font-semibold text-center md:text-left mb-4">Product Management</h5>
            {message && <p className="text-green-500 my-4">{message}</p>}
            <table className="w-full min-w-[800px] m-auto overflow-scroll px-4 py-5 border-collapse border border-gray-300">
                <thead>
                    <tr className='text-base-accent-1  text-left px-2 bg-gray-100'>
                        <th className="border border-base px-4 py-2">Image</th>
                        <th className="border  px-4 py-2">Name</th>
                        <th className="border  px-4 py-2">Price</th>
                        <th className="border border-gray-300 px-4  py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products?.map(product => (
                        <tr key={product.id} className='hover:bg-gray-50 duration-200 cursor-pointer'>
                            <td className="border border-gray-300 px-4 py-2">
                                <img src={product.images[0]?.imageUrl} alt={product.name} className="w-16 rounded-[50%] h-16 object-cover" />
                                </td>
                            <td className="border text-[13px] md:text-[15px] border-gray-300 px-4 py-2">{product.translations[0]?.name || product.name}</td>
                            <td className="border text-[13px] md:text-[15px] border-gray-300 px-4 py-2">${product.price}</td>
                            <td className="border  text-[13px] md:text-[15px]  px-4 py-2">
                               <div className='flex justify-between'>
                               <button className={`px-2 py-1 text-[13px] md:text-[15px] w-[100px] ${product.active === false ? 'text-red-500' : 'text-green-500'} rounded`}>
                                    {product.active === false ? 'Deactivate' : 'Activate'}
                                </button>
                                <div >
                                <Link to={`/admin/items/edit-product/${product.id}`}  className="ml-2 text-[13px] md:text-[15px] px-2 py-1 text-blue-500  rounded">
                                    Edit
                                </Link>
                                <button onClick={() => { setSelectedProduct(product); setIsDeleting(true); }} className="ml-2 text-[13px] md:text-[15px] px-2 py-1 text-red-500  rounded">
                                    Delete
                                </button>
                               </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

           

            {/* Confirmation Modal for Deleting Product */}
            {isDeleting && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-md">
                        <h3 className="text-lg font-bold">Confirm Deletion</h3>
                        <p>Are you sure you want to delete this product?</p>
                        <div className="mt-4">
                            <button onClick={handleDeleteProduct} className="bg-red-500 text-white px-4 py-2 rounded mr-2">Yes</button>
                            <button onClick={() => setIsDeleting(false)} className="bg-gray-300 text-black px-4 py-2 rounded">No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
        </div>
    );
};

export default ProductManagement;