import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import feedbackSectionImage from "../assets/images/home-testimonial-image-1.jpg";
import FeedBackBox from './mainpage/FeedBackBox';
import profile from "../assets/images/about-testimonial-03.webp"
const comments=[
    {id:1,name:'maria',job:"Designer",title:'These Pots Make Cooking A Pleasure',comment:"Mi proin sed libero enim sed. Vulputate odio ut enim blandit volutpat. In nulla posuere sollicitudin aliquam ultrices sagittis orci. Pharetra pharetra massa massa ultricies mi quis hendrerit dolor. Velit dignissim sodales ut eu sem.",profile}
    ,{id:2,name:'maria',job:"Designer",title:'These Pots Make Cooking A Pleasure',comment:"Mi proin sed libero enim sed. Vulputate odio ut enim blandit volutpat. In nulla posuere sollicitudin aliquam ultrices sagittis orci. Pharetra pharetra massa massa ultricies mi quis hendrerit dolor. Velit dignissim sodales ut eu sem.",profile}
    ,{id:3,name:'maria',job:"Designer",title:'These Pots Make Cooking A Pleasure',comment:"Mi proin sed libero enim sed. Vulputate odio ut enim blandit volutpat. In nulla posuere sollicitudin aliquam ultrices sagittis orci. Pharetra pharetra massa massa ultricies mi quis hendrerit dolor. Velit dignissim sodales ut eu sem.",profile}
     ,{id:4,name:'maria',job:"Designer",title:'These Pots Make Cooking A Pleasure',comment:"Mi proin sed libero enim sed. Vulputate odio ut enim blandit volutpat. In nulla posuere sollicitudin aliquam ultrices sagittis orci. Pharetra pharetra massa massa ultricies mi quis hendrerit dolor. Velit dignissim sodales ut eu sem.",profile}
    ,{id:5,name:'maria',job:"Designer",title:'These Pots Make Cooking A Pleasure',comment:"Mi proin sed libero enim sed. Vulputate odio ut enim blandit volutpat. In nulla posuere sollicitudin aliquam ultrices sagittis orci. Pharetra pharetra massa massa ultricies mi quis hendrerit dolor. Velit dignissim sodales ut eu sem.",profile}

]
const ClientFeedback = () => {
    return (
        <div className="slider-container w-11/12 m-auto">
            <h5 className='text-base-accent-1 mt-20 mb-5 text-center  text-[37px]'> Client Feedback </h5>
           <div className='flex flex-col md:flex-row '> 
            <div className='w-full md:w-5/12'>
                <img className='w-full h-[85%]'  src={feedbackSectionImage} alt=""/>
            </div>
            <div className='w-full md:w-7/12   '>
            <Swiper
                modules={[Navigation]}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: false,
                }}
                spaceBetween={30}
                slidesPerView={1}
                style={{
                    "--swiper-navigation-size": "15px",
                  }}
               
                    
            >
                {comments?.map(item=>
                <SwiperSlide>
                    <FeedBackBox item={item} />
                </SwiperSlide>

                )}
                
                <div className="swiper-button-next" >
                </div>
            </Swiper>
            </div>
        </div>
        </div>
    );
};

export default ClientFeedback;